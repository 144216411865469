import React from "react";
import ReactDOM from "react-dom";
import Auth from "./Auth";
import { HistoryProvider } from "./context/HistoryContext";
import { HashRouter, BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter basename="/app">
      <HistoryProvider>
        <Auth />
      </HistoryProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
