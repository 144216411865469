import axios from "axios";

import moment, { duration } from "moment/moment";
import "moment-timezone";

import { useEffect, useState } from "react";

let TOKEN =
  localStorage.getItem("TOKEN") ||
  "1000.22e0e5cfdc3ab1cf12c43b154d092154.a0adb34b4107229155a38352644426ed";
let zCRM;

async function axiosAuth(config = {}, alreadyNewToken = false) {
  return new Promise(async function(resolve, reject) {
    try {
      if (config?.headers?.["Content-Type"] == "multipart/form-data") {
        const response = await axios.post(
          "https://nocors.nocors.shyamalclinic.com/no-cors-multer",
          config.data,
          {
            ...config,
            headers: {
              url: config.url,
              "Content-Type": "multipart/form-data",
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      } else {
        const response = await axios.post(
          "https://nocors.shyamalclinic.com/no-cors",
          {
            ...config,
            data: config.data,
            headers: {
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      }
    } catch (error) {
      console.log(error, error.response?.data);
      if (
        !alreadyNewToken &&
        (error.response?.data?.code == "INVALID_TOKEN" ||
          error.response?.data?.code == "AUTHENTICATION_FAILURE")
      ) {
        // Token has expired, get a new TOKEN and make the API call again
        TOKEN = await getNewToken();
        return resolve(axiosAuth(config, true));
      } else {
        reject(error);
      }
    }
  });
}

async function getNewToken() {
  // Code to get a new token from Zoho API00
  console.log("GENERATE NEW API TOKEN");
  var config = {
    method: "post",
    url:
      "https://accounts.zohocloud.ca/oauth/v2/token?refresh_token=1000.9699bf92aa733ec2c1975372242ba33d.b3b08afe449117eb594f00424df55df1&client_id=1000.7HJ1FUTLTWSZDXGQEQFXSRK71BI4AL&client_secret=5c5b48542cd5553c228ebb78aee327277010325760&grant_type=refresh_token",
    headers: {
      "cache-control": "no-cache",
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post("https://rr.aquaorange.co.th/no_cors.php", config)
      .then(function(response) {
        console.log("TOKEN: ", response.data?.access_token);
        localStorage.setItem("TOKEN", response.data?.access_token);
        resolve(response.data?.access_token);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function removeLeadingZero(num) {
  // Convert the number to a string
  let numString = num.toString();

  // Remove the leading "0" if present
  let cleanedNumString = numString.replace(/^0+/, "");

  // Convert the cleaned string back to a number
  let cleanedNum = parseInt(cleanedNumString, 10);

  return cleanedNum;
}

let leResponseCoql = {};

const ScheduleCall = () => {
  const [visible, setVisible] = useState(false);
  const [zIndex, setZIndex] = useState(false)
  const [responseOfCoql, setResponseOfCoql] = useState({});
  const [personType, setPersonType] = useState("");
  const [personName, setPersonName] = useState("");
  const [callDescription, setCallDescription] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [callSubject, setCallSubject] = useState("Call scheduled with Unknown");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function fetchinCallInformation(phone) {
    setIsLoading(true);

    axiosAuth({
      method: "get",
      url:
        "https://www.zohoapis.ca/crm/v6/Contacts/search?criteria=Phone%3Aequals%3A" +
        phone,
    })
      .then((res) => {
        if (res.data) {
          const data = res.data.data[0];
          leResponseCoql = data;
          setResponseOfCoql(data);
          setPersonName(data.Full_Name);
          setCallSubject("Call scheduled with " + data.Full_Name);
          setPersonType("CONTACT");
          setErrorMessage("");
        } else {
          setPersonName("-");
          setErrorMessage(
            "Phone number is not present inside Contacts; we can't schedule a call"
          );
        }
        // setPersonName("")
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setErrorMessage("Something went wrong");
        leResponseCoql = {};
        setResponseOfCoql({});
      });

    // console.log(response)
  }

  function handleScheduleCall() {
    console.log(responseOfCoql, "RESPONSE");
    console.log(scheduleTime);

    if (personName == "-") {
      setErrorMessage(
        "Phone number is not present inside Contacts; we can't schedule a call"
      );
      return;
    }

    if (!personName || !callSubject || !scheduleTime) {
      setErrorMessage("Please fill above mandatory fields");
      return;
    } else {
      setErrorMessage("");
    }

    const now = moment();
    const dateToCheck = moment(scheduleTime);

    if (!dateToCheck.isAfter(now)) {
      setErrorMessage("The date is must be ahead than the current time");
      return;
    }

    const insterRecordCall = {
      Subject: callSubject,
      Owner: window.RC.zohoUser.id,
      Outgoing_Call_Status: "Scheduled",
      Call_Type: "Outbound",
      Call_Start_Time: moment
        .tz(scheduleTime, "Canada/Central")
        .format("yyyy-MM-DDTHH:mm:ss+07:00"),
      Call_Agenda: callDescription,
    };
    if (typeof leResponseCoql.Phone != "undefined") {
      insterRecordCall["Who_Id"] = leResponseCoql.id;
    } else if (typeof leResponseCoql.Mobile != "undefined") {
      insterRecordCall["What_Id"] = leResponseCoql.id;
      insterRecordCall["$se_module"] = "Leads";
    }

    setIsLoading(true);
    axiosAuth({
      url: "https://www.zohoapis.ca/crm/v2/Calls",
      method: "post",
      data: {
        data: [insterRecordCall],
      },
    })
      .then((res) => {
        setIsLoading(false);
        setVisible(false);
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function createNewScheduleCallLog(phone) {
    setVisible(true);

    setZIndex(++window.RC.zIndexPopup || 1);
    setResponseOfCoql({});
    setPersonType("");
    setPersonName("");
    setCallDescription("");
    setScheduleTime("");
    setCallSubject("Call scheduled with Unknown");
    setErrorMessage("");

    fetchinCallInformation(phone + "");
  }

  useEffect(() => {
    // fetchinCallInformation("14378364642");
  }, []);

  window.RC.component.callCreate.scheduleCall = createNewScheduleCallLog;

  return (
    <>
      {visible && (
        <div className="overlay-notifcation" style={{zIndex}}>
          <div className="receving-call-container2 call-schedule">
            <div className="call-schedule-form">
              <h6 className="mb-4">Schedule a call</h6>

              <div className="note-container">
                <div className="note-label">Call to</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input type="text" value={personName} disabled />
                    <div className="icon">
                      {personName != "" ? (
                        <img src="/img/lock.svg"/>
                      ) : (
                        <div className="spinner-border" role="status"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="note-container">
                <div className="note-label">Subject</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={callSubject}
                      onChange={(e) => setCallSubject(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="note-container">
                <div className="note-label">Desciption</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={callDescription}
                      onChange={(e) => setCallDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="note-container">
                <div className="note-label">Call Start Time</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input
                      type="datetime-local"
                      value={scheduleTime}
                      onChange={(e) => setScheduleTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {errorMessage && (
                <div className="note-container">
                  <div className="note-label"></div>
                  <div className="note-error">{errorMessage}</div>
                </div>
              )}

              <div className="note-footer mt-4">
                <div className="note-cancel" onClick={() => setVisible(false)}>
                  Cancel
                </div>
                {isLoading ? (
                  <div className="note-action">
                    <div className="spinner-border" role="status"></div>
                  </div>
                ) : (
                  <div
                    className="note-action"
                    onClick={() => {
                      handleScheduleCall();
                    }}
                  >
                    Schedule
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleCall;
