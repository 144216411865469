import axios from "axios";

import { useEffect, useState, useRef } from "react";
import moment, { duration } from "moment/moment";
import "moment-timezone";

let TOKEN =
  localStorage.getItem("TOKEN") ||
  "1000.22e0e5cfdc3ab1cf12c43b154d092154.a0adb34b4107229155a38352644426ed";
let zCRM;

async function axiosAuth(config = {}, alreadyNewToken = false) {
  return new Promise(async function(resolve, reject) {
    try {
      if (config?.headers?.["Content-Type"] == "multipart/form-data") {
        const response = await axios.post(
          "https://nocors.nocors.shyamalclinic.com/no-cors-multer",
          config.data,
          {
            ...config,
            headers: {
              url: config.url,
              "Content-Type": "multipart/form-data",
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      } else {
        const response = await axios.post(
          "https://nocors.shyamalclinic.com/no-cors",
          {
            ...config,
            data: config.data,
            headers: {
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      }
    } catch (error) {
      console.log(error, error.response?.data);
      if (
        !alreadyNewToken &&
        (error.response?.data?.code == "INVALID_TOKEN" ||
          error.response?.data?.code == "AUTHENTICATION_FAILURE")
      ) {
        // Token has expired, get a new TOKEN and make the API call again
        TOKEN = await getNewToken();
        return resolve(axiosAuth(config, true));
      } else {
        reject(error);
      }
    }
  });
}

async function getNewToken() {
  // Code to get a new token from Zoho API00
  console.log("GENERATE NEW API TOKEN");
  var config = {
    method: "post",
    url:
      "https://accounts.zohocloud.ca/oauth/v2/token?refresh_token=1000.9699bf92aa733ec2c1975372242ba33d.b3b08afe449117eb594f00424df55df1&client_id=1000.7HJ1FUTLTWSZDXGQEQFXSRK71BI4AL&client_secret=5c5b48542cd5553c228ebb78aee327277010325760&grant_type=refresh_token",
    headers: {
      "cache-control": "no-cache",
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post("https://rr.aquaorange.co.th/no_cors.php", config)
      .then(function(response) {
        console.log("TOKEN: ", response.data?.access_token);
        localStorage.setItem("TOKEN", response.data?.access_token);
        resolve(response.data?.access_token);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function removeLeadingZero(num) {
  // Convert the number to a string
  let numString = num.toString();

  // Remove the leading "0" if present
  let cleanedNumString = numString.replace(/^0+/, "");

  // Convert the cleaned string back to a number
  let cleanedNum = parseInt(cleanedNumString, 10);

  return cleanedNum;
}
let leResponseCoql = {}
let logCallType = ""
const LogCall = () => {

  const saveButton = useRef(null)
  const [visible, setVisible] = useState(false);
  const [zIndex, setZIndex] = useState(1)
  const [responseOfCoql, setResponseOfCoql] = useState({});
  const [personType, setPersonType] = useState("");
  const [personName, setPersonName] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [callType, setCallType] = useState("");
  const [callDuration, setCallDuration] = useState("");
  const [callDescription, setCallDescription] = useState("");
  const [callSubject, setCallSubject] = useState("Call with Unknown");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function fetchinCallInformation(phone) {
    const queryCombined = `
    (select Last_Name, First_Name, Full_Name, Created_Time
     from Contacts
     where ((Phone in ('${phone}','1${phone}','${removeLeadingZero(
      phone
    )}')) OR (Mobile in ('${phone}','1${phone}','${removeLeadingZero(phone)}')))
     order by Created_Time desc limit 1)
    UNION
    (select Last_Name, First_Name, Full_Name, Created_Time
     from Leads
     where ((Phone in ('${phone}','1${phone}','${removeLeadingZero(
      phone
    )}')) OR (Mobile in ('${phone}','1${phone}','${removeLeadingZero(phone)}')))
     order by Created_Time desc limit 1)
    order by Created_Time desc
    limit 1;
`;

    setIsLoading(true);

    axiosAuth({
      method: "get",
      url:
        "https://www.zohoapis.ca/crm/v6/Contacts/search?criteria=Phone%3Aequals%3A" +
        removeLeadingZero(phone),
    })
      .then((res) => {
        if (res.data) {
          const data = res.data.data[0];
          leResponseCoql = res.data.data[0];
          setResponseOfCoql(data);
          setPersonName(data.Full_Name);
          console.log(logCallType, "CALL TYPE")
          if (logCallType == "Inbound")
            setCallSubject(() => "Incomming call from " + data.Full_Name);
          if (logCallType == "Outbound")
            setCallSubject(() => "Outgoing call to " + data.Full_Name);
          if (logCallType == "Missed")
            setCallSubject(() => "Missed call " + data.Full_Name);
          setPersonType("CONTACT");
          setErrorMessage("");
        } else {
          if (logCallType == "Inbound")
            setCallSubject(() => "Incomming call from Unknown " + phone);
          if (logCallType == "Outbound")
            setCallSubject(() => "Outgoing call to Unknown " + phone);
          if (logCallType == "Missed")
            setCallSubject(() => "Missed call Unknown " + phone);
          setPersonName("-");
        }
        // setPersonName("")
        setIsLoading(false);
        console.log("xxxxx")
        if (logCallType == "Missed" || window?.RC?.status?.call?.isLoopCalls)
          saveButton.current?.click();
          // setTimeout(() => {
          //   handleCallLog();
          // }, 100);
      })            
      .catch((err) => {
        setResponseOfCoql({});
        leResponseCoql = {}
        console.log(err);
        setIsLoading(false);
        setErrorMessage("Something went wrong");
      });

    // console.log(response)
  }

  function handleCallLog() {
    console.log(leResponseCoql, "RESPONSE");
    let insterRecordCall;

    // if(typeof leResponseCoql.Phone != "undefined") {

    // }
    if (logCallType == "Inbound") {
      insterRecordCall = {
        Subject: callSubject,
        // Owner: zohoUser.id,
        Call_Type: logCallType,
        Owner: window.RC.zohoUser.id,
        Call_Duration: callDuration,
        Call_Start_Time: moment
          .tz("Canada/Central")
          .subtract(Number(callDuration?.split(":")?.[0]), "minutes")
          .subtract(Number(callDuration?.split(":")?.[1]), "seconds")
          .format("yyyy-MM-DDTHH:mm:ss+07:00"),
        Description: callDescription,
      };
      if (typeof leResponseCoql.Phone != "undefined")
        insterRecordCall["Who_Id"] = leResponseCoql.id;
      else if (typeof leResponseCoql.Mobile != "undefined") {
        insterRecordCall["What_Id"] = leResponseCoql.id;
        insterRecordCall["$se_module"] = "Leads";
      }
    } else if (logCallType == "Outbound") {
      insterRecordCall = {
        Subject: callSubject,
        Call_Type: logCallType,
        Owner: window.RC.zohoUser.id,
        Call_Duration: callDuration,
        Call_Start_Time: moment
          .tz("Canada/Central")
          .subtract(Number(callDuration?.split(":")?.[0]), "minutes")
          .subtract(Number(callDuration?.split(":")?.[1]), "seconds")
          .format("yyyy-MM-DDTHH:mm:ss+07:00"),
        Description: callDescription,
      };

      if (typeof leResponseCoql.Phone != "undefined")
        insterRecordCall["Who_Id"] = leResponseCoql.id;
      else if (typeof leResponseCoql.Mobile != "undefined") {
        insterRecordCall["What_Id"] = leResponseCoql.id;
        insterRecordCall["$se_module"] = "Leads";
      }
    } else if (logCallType == "Missed") {
      insterRecordCall = {
        Subject: callSubject,
        Call_Type: logCallType,
        Owner: window.RC.zohoUser.id,
        Call_Duration: callDuration,
        Call_Start_Time: moment
          .tz("Canada/Central")
          .subtract(Number(callDuration?.split(":")?.[0]), "minutes")
          .subtract(Number(callDuration?.split(":")?.[1]), "seconds")
          .format("yyyy-MM-DDTHH:mm:ss+07:00"),
        Description: callDescription,
      };

      if (typeof leResponseCoql.Phone != "undefined")
        insterRecordCall["Who_Id"] = leResponseCoql.id;
      else if (typeof leResponseCoql.Mobile != "undefined") {
        insterRecordCall["What_Id"] = leResponseCoql.id;
        insterRecordCall["$se_module"] = "Leads";
      }
    }

    setIsLoading(true);
    axiosAuth({
      url: "https://www.zohoapis.ca/crm/v2/Calls",
      method: "post",
      data: {
        data: [insterRecordCall],
      },
    })
      .then((res) => {
        setIsLoading(false);
        setVisible(false);
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function createNewCallLog(phone, type, duration, startTime) {
    console.log("create call log: ", phone, type, duration, startTime);
    if (type != "Missed" && !window?.RC?.status?.call?.isLoopCalls) {
      setVisible(true);
      setZIndex(++window.RC.zIndexPopup || 1);
    };
    setResponseOfCoql({});
    setPersonType("");
    setCallDescription("");
    setPersonName("");
    setScheduleTime(startTime);
    if (type == "Inbound") setCallSubject(() => "Incomming call from Unknown");
    if (type == "Outbound") setCallSubject(() => "Outgoing call to Unknown");
    if (type == "Missed") setCallSubject(() => "Missed call to Unknown");
    setErrorMessage("");
    setCallType(() => type);
    logCallType = type;
    setCallDuration(duration);
    setTimeout(() => {
      fetchinCallInformation(phone + "");
    }, 100);
  }

  useEffect(() => {
    
      window.RC.component.callCreate.logCall = createNewCallLog;
  }, []);

  return (
    <>
      {
        <div
          className="overlay-notifcation"
          style={{ zIndex, display: visible  ? "block" : "none"}}
        >
          <div className="receving-call-container2 call-schedule">
            <div className="call-schedule-form">
              <h6 className="mb-4">Log a call</h6>

              <div className="note-container">
                <div className="note-label">Call to</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input type="text" value={personName} disabled />
                    <div className="icon">
                      {personName != "" ? (
                        <>
                          <img src="/img/lock.svg" alt="" />
                        </>
                      ) : (
                        <div className="spinner-border" role="status"></div>
                      )}
                    </div>
                    <div className="badgex">
                      {typeof responseOfCoql.Phone != "undefined" ? (
                        <>
                          <span className="badge text-bg-primary">contact</span>
                        </>
                      ) : typeof responseOfCoql.Mobile != "undefined" ? (
                        <span className="badge text-bg-warning">lead</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="note-container">
                <div className="note-label">Subject</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={callSubject}
                      onChange={(e) => setCallSubject(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="note-container">
                <div className="note-label">Description</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={callDescription}
                      onChange={(e) => setCallDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="note-container">
                <div className="note-label">Call Type</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={logCallType}
                      disabled
                      // onChange={(e) => setCallSubject(e.target.value)("")}
                    />
                    <div className="icon">
                      <img src="/img/lock.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="note-container">
                <div className="note-label">Call Duration</div>
                <div className="note-action">
                  <div className="input-wrap">
                    <input
                      type="text"
                      disabled
                      value={`${callDuration?.split(":")?.[0] ||
                        "00"} mins ${callDuration?.split(":")?.[1] ||
                        "00"} seconds`}
                      // onChange={(e) => setScheduleTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {errorMessage && (
                <div className="note-container">
                  <div className="note-label"></div>
                  <div className="note-error">{errorMessage}</div>
                </div>
              )}

              <div className="note-footer mt-4">
                <div className="note-cancel" onClick={() => setVisible(false)}>
                  Cancel
                </div>
                {isLoading ? (
                  <div className="note-action">
                    <div className="spinner-border" role="status"></div>
                  </div>
                ) : (
                  <div
                    className="note-action"
                    ref={saveButton}
                    onClick={() => {
                      handleCallLog();
                    }}
                  >
                    Save
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default LogCall;
