import "./App.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import Notes_main from "./Notes_main";
import CarCaller from "./car_caller.jsx";
import Home from "./components/home/Home";
import CallScreen from "./components/call-screen/CallScreen";
import HistoryScreen from "./components/history-screen/HistoryScreen";
import useJssip from "./hooks/useJssip";
import { useCallback, useEffect, useState } from "react";
import { FiPhone } from "react-icons/fi";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";

import { Route, Routes, HashRouter, useNavigate } from "react-router-dom";

import AQUAORANGEIMAGE from "./components/home/aquaorange.jpeg";
import LogCall from "./components/CallLog";
import ScheduleCall from "./components/ScheduleCall";
import IncommingCall from "./components/IncommingCall";

const apiCache = {};
const apiAlreadyCalled = {};
var phoneNameMapping = {};


function extractNumbers(inputString) {
  // Use regular expression to replace non-numeric characters with an empty string
  return inputString.replace(/[^0-9]/g, "");
}

function removeLeadingZero(num) {
  // Convert the number to a string
  let numString = num.toString();

  // Remove the leading "0" if present
  let cleanedNumString = numString.replace(/^0+/, "");

  // Convert the cleaned string back to a number
  let cleanedNum = parseInt(cleanedNumString, 10);

  return cleanedNum;
}

let TOKEN =
  localStorage.getItem("TOKEN") ||
  "1000.22e0e5cfdc3ab1cf12c43b154d092154.a0adb34b4107229155a38352644426ed";
let zCRM;

async function axiosAuth(config = {}, alreadyNewToken = false) {
  return new Promise(async function(resolve, reject) {
    try {
      if (config?.headers?.["Content-Type"] == "multipart/form-data") {
        const response = await axios.post(
          "https://nocors.nocors.shyamalclinic.com/no-cors-multer",
          config.data,
          {
            ...config,
            headers: {
              url: config.url,
              "Content-Type": "multipart/form-data",
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      } else {
        const response = await axios.post(
          "https://nocors.shyamalclinic.com/no-cors",
          {
            ...config,
            data: config.data,
            headers: {
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      }
    } catch (error) {
      console.log(error, error.response?.data);
      if (
        !alreadyNewToken &&
        (error.response?.data?.code == "INVALID_TOKEN" ||
          error.response?.data?.code == "AUTHENTICATION_FAILURE")
      ) {
        // Token has expired, get a new TOKEN and make the API call again
        TOKEN = await getNewToken();
        return resolve(axiosAuth(config, true));
      } else {
        reject(error);
      }
    }
  });
}

async function getNewToken() {
  // Code to get a new token from Zoho API00
  console.log("GENERATE NEW API TOKEN");
  var config = {
    method: "post",
    url:
      "https://accounts.zohocloud.ca/oauth/v2/token?refresh_token=1000.9699bf92aa733ec2c1975372242ba33d.b3b08afe449117eb594f00424df55df1&client_id=1000.7HJ1FUTLTWSZDXGQEQFXSRK71BI4AL&client_secret=5c5b48542cd5553c228ebb78aee327277010325760&grant_type=refresh_token",
    headers: {
      "cache-control": "no-cache",
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post("https://rr.aquaorange.co.th/no_cors.php", config)
      .then(function(response) {
        console.log("TOKEN: ", response.data?.access_token);
        localStorage.setItem("TOKEN", response.data?.access_token);
        resolve(response.data?.access_token);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

let renderCount = 0;

let inCommingInfo = [];

async function* getPhoneNumberInfo(phone) {
  console.log("FIGURE OUT NUMBER: XXX", phone);

  const queryContact = `select Last_Name, First_Name, Full_Name
    from Contacts
    where ((Phone in ('${phone}','0${phone}','${removeLeadingZero(
    phone
  )}')) OR (Mobile in ('${phone}','0${phone}','${removeLeadingZero(phone)}')))
    order by Created_Time desc limit 1`;

  const queryLead = `select Last_Name, First_Name, Full_Name, Lead_Status
    from Leads
    where ((Phone in ('${phone}','0${phone}','${removeLeadingZero(
    phone
  )}')) OR (Mobile in ('${phone}','0${phone}','${removeLeadingZero(phone)}')))
    order by Created_Time desc limit 1`;

  const configContact = {
    select_query: queryContact,
  };
  const configLead = {
    select_query: queryLead,
  };

  const callToIfContact = await window.ZOHO.CRM.API.coql(configContact).then(
    function(data) {
      if (data.data) {
        return data.data?.[0];
      }
      return {};
    }
  );

  const callToIfLead = await window.ZOHO.CRM.API.coql(configLead).then(function(
    data
  ) {
    if (data.data) {
      return data.data?.[0];
    }
    return {};
  });
  let contactInfo;
  if (callToIfContact.id) {
    contactInfo = {
      type: "Contact",
      data: { ...callToIfContact, phone: phone },
    };
  } else if (callToIfLead.id) {
    contactInfo = { type: "Lead", data: { ...callToIfLead, phone: phone } };
  } else {
    contactInfo = {
      type: "New",
      data: {
        Full_Name: "Unknown",
        phone: phone,
      },
    };
  }

  console.log("Contact INFO", "Xxx");
  if (callToIfContact.id) {
    yield { type: "Contact", data: { ...callToIfContact, phone: phone } };
  } else if (callToIfLead.id) {
    yield { type: "Lead", data: { ...callToIfLead, phone: phone } };
  } else {
    yield {
      type: "New",
      data: {
        Full_Name: "Unknown",
        phone: phone,
      },
    };
  }

  while (true) {
    yield contactInfo;
  }
}

console.log("[TELEPHONEY]: APP SETUP");
/* 
window.onbeforeunload = function (event) {
  console.log("CALL ON RELOAD 1")
  return window.confirm("Confirm refresh");
};

window.addEventListener("beforeunload", function (e) {
  console.log("EVENT :CALL ON RELOAD 2")
  var confirmationMessage = "\o/";     
  e.returnValue = confirmationMessage;           
  return confirmationMessage;       

}); */

function CallComponent({ setAppReload }) {
  renderCount += 1;
  // console.log("Render Count:", renderCount)
  const [
    connectionStatus,
    seconds,
    minutes,
    status,
    phoneNumber,
    setPhoneNumber,
    handleCall,
    session,
    speakerOff,
    setSpeakerOff,
    isRunning,
    audioRef,
    incommingSession,
    accepetCall,
    rejectCall,
    queryPhoneNumber,
    queryPhonePassword,
    CallEndComponent,
  ] = useJssip();
  const [seeLogs, setSeeLogs] = useState(false);

  const [reload, setReload] = useState(Math.random());
  const [reload2, setReload2] = useState({ r: "r" });

  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  function renderNameBasedOnPhonenumber() {
    setTimeout(() => {
      for (let key in phoneNameMapping) {
        if (document.getElementById(`phone-number-${key}`)) {
          document.getElementById(`phone-number-${key}`).innerHTML =
            phoneNameMapping[key];
        }
      }
      for (let key in phoneNameMapping) {
        if (document.getElementById(`answered-phone-number-${key}`)) {
          document.getElementById(`answered-phone-number-${key}`).innerHTML =
            phoneNameMapping[key];
        }
      }
    }, 200);
  }

  useEffect(() => {
    return () => {};
  }, [reload, reload2]);

  useEffect(() => {
    inCommingInfo = [];
    try {
      console.log("It's working call", incommingSession?.[0]?.e?.request?.from?._uri?.user)
      if (incommingSession[0].e?.request?.from?._uri?.user)
        window.RC.status.call.handleIncommingCall(
          (incommingSession?.[0]?.e?.request?.from?._uri?.user || "")
        );
      // window.RC.status.call.handleIncommingCall("14388847462");
    } catch (err) {}
    incommingSession.map((e, index) => {
      let genr = getPhoneNumberInfo(
        extractNumbers(
            (e?.e?.request?.from?._uri?.user || "")
        )
      );
      genr.next().then((res) => {
        console.log(res, "AAAA");
        inCommingInfo[index] = res;
        setReload(Math.random());
      });
    });
  }, [incommingSession]);

  // useEffect(() => {
  //   if (!window.RC.loggin) {
  //     window.RC.login(
  //       window.RC.config.Server,
  //       window.RC.config.AppKey,
  //       window.RC.config.AppSecret
  //     );
  //   }
  // }, []);



  return (
    <div className="App">
      {/* <div>
        {connectionStatus}
      </div> */}

      <>
        {/* <div className="add-note-wrap">
          <div className="add-note-container">
            <div className="note-title">
              <h6>
                Incoming call from test
              </h6>
            </div>
            <div>
              <label htmlFor="Notes">Notes</label>
            </div>
            <textarea name="" rows="3" style={{ width: "100%" }} placeholder="Add notes"></textarea>

            <div className="add-note-button">
              Add
            </div>
            <div className="add-note-button">
              <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div> */}
        <CallEndComponent></CallEndComponent>

        <div className="overlay-fix" id="session-present">
          <div className="overlay-container">
            <p>SIP session is already open somewhere</p>
            <div className="reload-btn">
              <svg
                onClick={() => {
                  window.location.reload();
                }}
                fill="#000000"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1,12A11,11,0,0,1,17.882,2.7l1.411-1.41A1,1,0,0,1,21,2V6a1,1,0,0,1-1,1H16a1,1,0,0,1-.707-1.707l1.128-1.128A8.994,8.994,0,0,0,3,12a1,1,0,0,1-2,0Zm21-1a1,1,0,0,0-1,1,9.01,9.01,0,0,1-9,9,8.9,8.9,0,0,1-4.42-1.166l1.127-1.127A1,1,0,0,0,8,17H4a1,1,0,0,0-1,1v4a1,1,0,0,0,.617.924A.987.987,0,0,0,4,23a1,1,0,0,0,.707-.293L6.118,21.3A10.891,10.891,0,0,0,12,23,11.013,11.013,0,0,0,23,12,1,1,0,0,0,22,11Z" />
              </svg>
            </div>
            <div className="fa fa-reload"></div>
          </div>
        </div>

        <div className="receving-call-container">
          {incommingSession.map((e, index) => (
            <div className="receving-call" key={e.id}>
              <div className="name">
                <div className="l1">
                  {console.log(e?.e?.request, "e?.e?.request")}
                  
                  {e?.e?.request?.from?._uri?.user || ""}
                </div>
                {inCommingInfo?.[index]?.value?.type == "Contact" && (
                  <>
                    <div className="d-flex">
                      <p style={{ marginBottom: "0px" }}>
                        <a
                          style={{ color: "white" }}
                          href="javascript:void(0)"
                          onClick={() => {
                            window.ZOHO.CRM.UI.Record.open({
                              Entity: "Contacts",
                              RecordID: inCommingInfo?.[index]?.value?.data?.id,
                            }).then(function(data) {
                              console.log(data);
                            });
                          }}
                        >
                          {inCommingInfo?.[index]?.value?.data?.Full_Name}
                        </a>
                      </p>
                    </div>
                  </>
                )}
                {/* <div className="l2" id={`phone-number-${extractNumbers(e?.e?.request?.from?._display_name)}`}></div> */}
                {/* {getNumberName(extractNumbers(e?.e?.request?.from?._display_name))} */}
              </div>
              <div className="answer">
                <div className="no" onClick={() => rejectCall(e)}>
                  <FiPhone className="call-icon" />
                </div>
                <div className="yes" onClick={() => accepetCall(e)}>
                  <FiPhone className="call-icon" />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="receving-call-container">
          <div className="receving-call" >
            <div className="name">
              <div className="l1">20968725</div>
              <div className="l2" id="phone-number-20968725"></div>
              {getNumberName("20968725")}
            </div>
            <div className="answer">
              <div className="no" onClick={() => { }}><FiPhone className="call-icon" /></div>
              <div className="yes" onClick={() => { }}><FiPhone className="call-icon" /></div>
            </div>
          </div>
        </div> */}
        {seeLogs ? (
          <HistoryScreen setSeeLogs={setSeeLogs} />
        ) : status !== "calling" ? (
          <Home
            connectionStatus={connectionStatus}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            setAppReload={setAppReload}
            handleCall={() => handleCall()}
            setSeeLogs={setSeeLogs}
            queryPhoneNumber={queryPhoneNumber}
          />
        ) : (
          <CallScreen
            renderName={renderNameBasedOnPhonenumber}
            phoneNumber={phoneNumber}
            session={session}
            speakerOff={speakerOff}
            setSpeakerOff={setSpeakerOff}
            seconds={secondTime}
            minutes={minuteTime}
            isRunning={isRunning}
          />
        )}
        <audio ref={audioRef} autoPlay hidden={true} muted={speakerOff} />
      </>
    </div>
  );
}

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const [appReload, setAppReload] = useState(true);

  
  const [activeSection, setActiveSection] = useState("keypad"); // Default active is keypad_main

  const sections = [
    { name: "keypad", icon: "/img/keypad_nav.svg", label: "Keypad" },
    { name: "recent", icon: "/img/call.svg", label: "Recent" },
    { name: "contact", icon: "/img/tag-user.svg", label: "Contact" },
    { name: "chats", icon: "/img/messages.svg", label: "Chats" },
    // { name: "logout", icon: "/img/logout.svg", label: "Logout" },
  ];

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="App">
      {/* <div>
        {connectionStatus}
      </div> */}

      <section className="main_section">
        <section className="Notes_main_1">
          {/* <header className="d-flex justify-content-between align-items-center px-3">
            <i
              className="bi bi-arrow-clockwise roate-360-hover"
              style={{
                color: "#df251f",
                cursor: "pointer",
              }}
              onClick={() => {
                window.ZOHO.CRM.UI.Popup.closeReload().then(function(data) {
                  console.log(data);
                });
                // window.location.reload();
              }}
            ></i>
            <div className="title">Cars & Bids</div>
            <i
              className="bi bi-dash-circle"
              style={{
                color: "#2ca426",
                cursor: "pointer",
              }}
              onClick={() => {
                try {
                  window.ZOHO.CRM.UI.Dialer.minimize();
                } catch (err) {
                  console.log(err);
                }
              }}
            ></i>
          </header> */}
        </section>
        <section className="row main-for-whole-section">
        <section className="side_menu d-flex flex-column justify-content-around col-1">
      {sections.map((section) => (
        <div
          key={section.name}
          className={`side_main_every text-center ${activeSection === section.name ? "active" : ""}`}
          onClick={() => handleSectionClick(section.name)}
        >
          <div className="side_active_sec"></div>
          <div className="side_logo">
            <img src={section.icon} alt={section.label} />
          </div>
          <div className="side_txt">{section.label}</div>
        </div>
      ))}
    </section>
          <section
            className="col-4"
            style={{ paddingRight: "0px",}}
          >
            <CallComponent setAppReload={setAppReload} />
            {/* <div className="Notes_main_2 d-flex flex-column align-items-center">
              <div className="contact_icon">
                <div>
                  <img src="/images/Group 45.svg" alt="" />
                </div>
              </div>
              <div className="NameNum">
                <h1>Jimmy Roy</h1>
                <p>+1232457896</p>
              </div>
              <div className="gridIcons row">
                <div className="gridItem col-4">
                  <div>
                    <img src="/images/Group 44.svg" alt="" />
                  </div>
                  <p>Mute</p>
                </div>
                <div className="gridItem col-4">
                  <div>
                    <img src="/images/Group 41.svg" alt="" />
                  </div>
                  <p>Keypad</p>
                </div>
                <div className="gridItem col-4">
                  <div>
                    <img src="/images/Group 42.svg" alt="" />
                  </div>
                  <p>Audio</p>
                </div>
                <div className="gridItem col-4">
                  <div>
                    <img src="/images/Group 43.svg" alt="" />
                  </div>
                  <p>Hold</p>
                </div>
                <div className="gridItem col-4">
                  <div>
                    <img src="/images/Group 44.svg" alt="" />
                  </div>
                  <p>Transfer</p>
                </div>
              </div>
              <div className="red_icon">
                <Link to="/">
                  <img src="/images/call_end.svg" alt="" />
                </Link>
              </div>
            </div> */}
          </section>
          <>
            <LogCall></LogCall>
            <ScheduleCall></ScheduleCall>
            <IncommingCall></IncommingCall>
            <Routes>
              <Route path="/" element={<CarCaller />}></Route>
              <Route path="/note" element={<Notes_main />}></Route>
            </Routes>
          </>
        </section>
      </section>
    </div>
  );
}

export default App;
