import axios from "axios";

import moment, { duration } from "moment/moment";
import "moment-timezone";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

let TOKEN =
  localStorage.getItem("TOKEN") ||
  "1000.22e0e5cfdc3ab1cf12c43b154d092154.a0adb34b4107229155a38352644426ed";
let zCRM;

async function axiosAuth(config = {}, alreadyNewToken = false) {
  return new Promise(async function(resolve, reject) {
    try {
      if (config?.headers?.["Content-Type"] == "multipart/form-data") {
        const response = await axios.post(
          "https://nocors.nocors.shyamalclinic.com/no-cors-multer",
          config.data,
          {
            ...config,
            headers: {
              url: config.url,
              "Content-Type": "multipart/form-data",
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      } else {
        const response = await axios.post(
          "https://nocors.shyamalclinic.com/no-cors",
          {
            ...config,
            data: config.data,
            headers: {
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      }
    } catch (error) {
      console.log(error, error.response?.data);
      if (
        !alreadyNewToken &&
        (error.response?.data?.code == "INVALID_TOKEN" ||
          error.response?.data?.code == "AUTHENTICATION_FAILURE")
      ) {
        // Token has expired, get a new TOKEN and make the API call again
        TOKEN = await getNewToken();
        return resolve(axiosAuth(config, true));
      } else {
        reject(error);
      }
    }
  });
}

async function getNewToken() {
  // Code to get a new token from Zoho API00
  console.log("GENERATE NEW API TOKEN");
  var config = {
    method: "post",
    url:
      "https://accounts.zohocloud.ca/oauth/v2/token?refresh_token=1000.9699bf92aa733ec2c1975372242ba33d.b3b08afe449117eb594f00424df55df1&client_id=1000.7HJ1FUTLTWSZDXGQEQFXSRK71BI4AL&client_secret=5c5b48542cd5553c228ebb78aee327277010325760&grant_type=refresh_token",
    headers: {
      "cache-control": "no-cache",
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post("https://rr.aquaorange.co.th/no_cors.php", config)
      .then(function(response) {
        console.log("TOKEN: ", response.data?.access_token);
        localStorage.setItem("TOKEN", response.data?.access_token);
        resolve(response.data?.access_token);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function removeLeadingZero(num) {
  // Convert the number to a string
  let numString = num.toString();

  // Remove the leading "0" if present
  let cleanedNumString = numString.replace(/^0+/, "");

  // Convert the cleaned string back to a number
  let cleanedNum = parseInt(cleanedNumString, 10);

  return cleanedNum;
}

let leResponseCoql = {};

const IncommingCall = () => {
  const [visible, setVisible] = useState(false);
  const [zIndex, setZIndex] = useState(1);
  const [responseOfCoql, setResponseOfCoql] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const [isSearchContact, setisSearchContact] = useState(false);
  const [isContactCompaniesLoading, setIsContactCompaniesLoading] = useState(
    false
  );

  const [incomingCall, setIncomingCall] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactId, setContactId] = useState("");
  const [contact, setContact] = useState({});
  const [componies, setComponies] = useState([]);
  const [dealerId, setDealerId] = useState("");
  const navigate = useNavigate();

  const fetchPhoneData = async (phone) => {
    try {
      setisSearchContact(true);
      setIsContactCompaniesLoading(true);
      const response = await axiosAuth({
        method: "get",
        url:
          "https://www.zohoapis.ca/crm/v6/Contacts/search?criteria=Phone%3Aequals%3A" +
          phone,
      });

      setisSearchContact(false);
      const phoneData = response.data?.data?.[0];
      console.log(phoneData);
      if (phoneData) {
        setPhoneNumber(phoneData.Phone);
        setContactId(phoneData.id);
        setContact(phoneData);
        setIncomingCall(true);

        fetchContactComponies(phoneData.id);
      } else {
        setIsContactCompaniesLoading(false);
        setErrorMessage(`ZOHO Contacts doesn't have this phone : ${phone}`);
      }
    } catch (error) {
      setisSearchContact(false);
      setIsContactCompaniesLoading(false);
      console.error("Error fetching phone data:", error);
    }
  };
  useEffect(() => {
    function handleCallInfo(phone) {
      setVisible(true);
      setZIndex(++window.RC.zIndexPopup || 1);

      setErrorMessage("");
      setResponseOfCoql({});

      setPhoneNumber("");
      setContactId("");
      setContact({});
      setComponies([]);
      setDealerId("");

      fetchPhoneData(phone + "");
    }

    // window.RC.status.call.handleIncommingCall = handleCallInfo;
    window.RC.status.call.contactInformation = handleCallInfo;
  }, []);

  const fetchContactComponies = async (id) => {
    try {
      const response = await axiosAuth({
        method: "get",
        url: `https://www.zohoapis.ca/crm/v6/Contacts/${id}`,
      });

      setIsContactCompaniesLoading(false);
      if (response.data) {
        console.log(
          "response?.data?.data?.[0]?.Companies",
          response?.data?.data?.[0]?.Companies
        );
        setComponies(response?.data?.data?.[0]?.Companies || []);
      }
      //  const contactNameFromAPI = response.data.data[0].Full_Name;
      //  const dealerNameFormAPI =
      //    response.data.data[0].Companies[0].Companies.name;

      //  console.log("Contact Name :", contactNameFromAPI);
      //  console.log("Dealer Name :", dealerNameFormAPI);

      //  const dealerIdFromAPI = response.data.data[0].Companies[0].Companies.id;
      //  console.log(dealerIdFromAPI);
      // setDealerId(dealerIdFromAPI);
      //  navigate("/note", { state: { contactId, dealerId: dealerIdFromAPI } });
    } catch (error) {
      setIsContactCompaniesLoading(false);
      setErrorMessage("Error fetching contact componies data:");
    }
  };

  const handleRejectClick = () => {
    setIncomingCall(false);
  };

  async function fetchinCallInformation(phone) {}

  return (
    <>
      {visible && (
        <div className="overlay-notifcation" style={{ zIndex: zIndex }}>
          <div className="receving-call-container2 call-schedule">
            <div className="call-schedule-form">
              <h6 className="mb-3">Contact Information </h6>

              {!errorMessage && (
                <>
                  {isSearchContact ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <span className="loader"></span>
                      </div>
                      {/* <span className="loader"></span> */}
                    </>
                  ) : (
                    <div className="row">
                      <div className="col-2" style={{ width: "50px" }}>
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="18"
                            cy="18"
                            r="17.5"
                            fill="#D9D9D9"
                            stroke="#AEAEAE"
                          />
                          <text
                            x="50%"
                            y="50%"
                            textAnchor="middle"
                            dy=".3em"
                            fontSize="16"
                            fontWeight={700}
                            fill="#3A3033"
                            fontFamily="Arial, sans-serif"
                          >
                            {contact?.Full_Name?.charAt(0)?.toUpperCase()}
                          </text>
                        </svg>
                      </div>
                      <div className="col-9">
                        <div
                          className="name_num"
                          onClick={() => {
                            window.ZOHO.CRM.UI.Record.open({
                              Entity: "Contacts",
                              RecordID: contact.id,
                            }).then(function(data) {
                              console.log(data);
                            });
                          }}
                        >
                          <div className="name">{contact.Full_Name}</div>
                          <div className="number">{contact.Phone}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isSearchContact && (
                    <>
                      <div className="row mt-4">
                        <div className="col-12">
                          <h6 style={{ fontSize: "13px" }} className="mb-2">
                            Contact Dealers
                          </h6>
                        </div>
                        <div
                          className="col-12"
                          style={{
                            maxHeight: "250px",
                            overflow: "auto",
                          }}
                        >
                          {isContactCompaniesLoading ? (
                            <>
                              <div className="d-flex justify-content-center">
                                <span className="loader"></span>
                              </div>
                            </>
                          ) : (
                            <table className="table table-sm accounts-table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">#</th>
                                  <th
                                    scope="col"
                                    style={{
                                      width: "75%",
                                    }}
                                  >
                                    Name
                                  </th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {componies.length == 0 ? (
                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{ padding: "20px 0px" }}
                                      colSpan="3"
                                    >
                                      No Dealers for this contact
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {componies.map((c, index) => (
                                      <tr key={c.id}>
                                        {console.log(c, "Xxx")}
                                        <th scope="row">{index + 1}</th>
                                        <td
                                          className="cst-hover"
                                          onClick={() => {
                                            window.ZOHO.CRM.UI.Record.open({
                                              Entity: "Accounts",
                                              RecordID: c.Companies.id,
                                            }).then(function(data) {
                                              console.log(data);
                                            });
                                          }}
                                        >
                                          {c.Companies.name}
                                        </td>
                                        <td>
                                          <div
                                            className="badge text-bg-success cst-hover"
                                            onClick={() => {
                                              navigate("/");
                                              navigate("/note", {
                                                state: {
                                                  contactId: contact.id,
                                                  dealerId: c.Companies.id,
                                                },
                                                replace: true,
                                              });
                                              setVisible(false);
                                            }}
                                          >
                                            <i
                                              className="bi bi-link"
                                              style={{ marginRight: "3px" }}
                                            ></i>
                                            Open
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {errorMessage && (
                <div className="note-container">
                  <div className="note-error">{errorMessage}</div>
                </div>
              )}

              <div className="note-footer mt-4">
                <div className="note-cancel" onClick={() => setVisible(false)}>
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IncommingCall;
