import "./home.scss";
import { useState } from "react";
import { FiPhone } from "react-icons/fi";
import { VscHistory } from "react-icons/vsc";
import { TiBackspaceOutline, TiBackspace } from "react-icons/ti";
import useFormatPhoneNumber from "./../../hooks/useFormatPhoneNumber";
import KeyPad from "../key-pad/KeyPad";
import AQUAORANGEIMAGE from "./aquaorange.jpeg";
import LOOGOUTIMAGE from "./logout.png";
const Home = ({
  phoneNumber,
  setPhoneNumber,
  handleCall,
  setSeeLogs,
  connectionStatus,
  setAppReload,
  queryPhoneNumber,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const formatPhoneNumber = useFormatPhoneNumber();

  const logout = function() {
    window.RC.logOut();
    localStorage.removeItem("sip-config");
    setAppReload(Math.random());
    window?.UA?.stop();
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };
  return (
    <div className="mobile-container">
      <div className="header">
        <div className="fa fa-star2 logout-btn">
          <img
            src={LOOGOUTIMAGE}
            style={{ width: "19px", cursor: "pointer" }}
            alt=""
            srcSet=""
            onClick={logout}
          />
        </div>

        <div
          className="logo"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <img src={AQUAORANGEIMAGE} onClick={() => {
              window.ZOHO.CRM.UI.Dialer.minimize();
            }} style={{
              width: "120px",
              transform: "translateY(-2px)"
            }} /> */}
            {/* <p style={{
              marginBottom: "0px",
              paddingRight: "5px"
            }}>AutoNerd</p> */}
            <small className="phone-number">{queryPhoneNumber}</small>
          </span>
          <div className="connection-status mx-1">
            {/* {connectionStatus} */}
            <div
              className={`status ${connectionStatus}`}
              style={{
                transform: "translateY(2px)",
                marginLeft: "6px",
              }}
              onClick={() => {
                if (connectionStatus == "connected") {
                  window.UA.stop();
                } else if (connectionStatus == "disconnected") {
                  window.UA.stop();
                  window.UA.start();
                }
              }}
            ></div>
          </div>
        </div>

        {/* <h1>res</h1> */}
        {/* <div
          className="history-btn"
          onClick={() => {
            setSeeLogs(true);
          }}
        >
          <VscHistory />
        </div> */}
      </div>
      <div className="text-center py-2">
        <img src="/img/phone-logo.png" alt="" srcset="" />
      </div>
      <div className="input-wrapper">
        <input
          type="number"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          placeholder="Phone number"
          className="phone-number-input"
        />
        {phoneNumber && (
          <div
            className="delete-btn"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() =>
              setPhoneNumber((prev) => {
                return prev.slice(0, -1).trim();
              })
            }
          >
            <img
              src="/img/close-circle-black.svg"
              alt=""
              srcset=""
            />
          </div>
        )}
      </div>

      <KeyPad setPhoneNumber={setPhoneNumber} />

      <button className="call-btn" onClick={handleCall}>
        <img src="/img/Dailer_1.png" alt="" srcset="" />
      </button>
    </div>
  );
};

export default Home;
