import { useEffect, useState } from "react";
import "./car_caller.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ScheduleCall from "./components/ScheduleCall";
import LogCall from "./components/CallLog";

let TOKEN =
  localStorage.getItem("TOKEN") ||
  "1000.22e0e5cfdc3ab1cf12c43b154d092154.a0adb34b4107229155a38352644426ed";
let zCRM;

async function axiosAuth(config = {}, alreadyNewToken = false) {
  return new Promise(async function(resolve, reject) {
    try {
      if (config?.headers?.["Content-Type"] == "multipart/form-data") {
        const response = await axios.post(
          "https://nocors.nocors.shyamalclinic.com/no-cors-multer",
          config.data,
          {
            ...config,
            headers: {
              url: config.url,
              "Content-Type": "multipart/form-data",
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      } else {
        const response = await axios.post(
          "https://nocors.shyamalclinic.com/no-cors",
          {
            ...config,
            data: config.data,
            headers: {
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      }
    } catch (error) {
      console.log(error, error.response?.data);
      if (
        !alreadyNewToken &&
        (error.response?.data?.code == "INVALID_TOKEN" ||
          error.response?.data?.code == "AUTHENTICATION_FAILURE")
      ) {
        // Token has expired, get a new TOKEN and make the API call again
        TOKEN = await getNewToken();
        return resolve(axiosAuth(config, true));
      } else {
        reject(error);
      }
    }
  });
}

async function getNewToken() {
  // Code to get a new token from Zoho API00
  console.log("GENERATE NEW API TOKEN");
  var config = {
    method: "post",
    url:
      "https://accounts.zohocloud.ca/oauth/v2/token?refresh_token=1000.9699bf92aa733ec2c1975372242ba33d.b3b08afe449117eb594f00424df55df1&client_id=1000.7HJ1FUTLTWSZDXGQEQFXSRK71BI4AL&client_secret=5c5b48542cd5553c228ebb78aee327277010325760&grant_type=refresh_token",
    headers: {
      "cache-control": "no-cache",
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post("https://rr.aquaorange.co.th/no_cors.php", config)
      .then(function(response) {
        console.log("TOKEN: ", response.data?.access_token);
        localStorage.setItem("TOKEN", response.data?.access_token);
        resolve(response.data?.access_token);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
let count = 0;
const CarCaller = () => {
  count += 1;
  const [currentDealerIndex, setCurrentDealerIndex] = useState(
    JSON.parse(localStorage.getItem("call-screen-info-dealer-index") || 0) || 0
  );

  const [reload, setReload] = useState(Math.random);

  const [incomingCall, setIncomingCall] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactId, setContactId] = useState("");
  const [contact, setContact] = useState({});
  const [dealerId, setDealerId] = useState("");
  const navigate = useNavigate();

  const jsonData2 = JSON.parse(localStorage.getItem("call-screen-info"));

  let formatedJson, jsonData, currentDealer;
  try {
    if (jsonData2?.Leads?.length && !jsonData2?.Leads?.[currentDealerIndex]) {
      setCurrentDealerIndex(0);
    }

    formatedJson = {
      vehicle_info: jsonData2.vehicle_info,
      lead_info: {
        id: "3384000000594238",
      },
      Dealers: jsonData2.Leads.map((i) => ({
        lead_info: i.lead_info,
        contacts: i.contacts,
        id: i.Dealers.id,
        info: i.Dealers.info,
        name: i.Dealers.name,
      })),
    };
    jsonData = formatedJson;

    currentDealer = jsonData.Dealers[currentDealerIndex];
  } catch (err) {}

  // console.log("calls count: ", count);

  useEffect(() => {
    const interval = setInterval(() => {
      setReload(Math.random());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchPhoneData = async (phone) => {
      try {
        const response = await axiosAuth({
          method: "get",
          url:
            "https://www.zohoapis.ca/crm/v6/Contacts/search?criteria=Phone%3Aequals%3A" +
            phone,
        });
        const phoneData = response.data?.data?.[0];
        console.log(phoneData);
        if (phoneData) {
          setPhoneNumber(phoneData.Phone);
          setContactId(phoneData.id);
          setContact(phoneData);
          setIncomingCall(true);
        }
      } catch (error) {
        console.error("Error fetching phone data:", error);
      }
    };

    // window.RC.status.call.handleIncommingCall = fetchPhoneData;
  }, []);

  const handleAcceptClick = async () => {
    try {
      const response = await axiosAuth({
        method: "get",
        url: `https://www.zohoapis.ca/crm/v6/Contacts/${contactId}`,
      });
      console.log("Contact API Response:", response.data.data[0]);
      const contactNameFromAPI = response.data.data[0].Full_Name;
      const dealerNameFormAPI =
        response.data.data[0].Companies[0].Companies.name;

      console.log("Contact Name :", contactNameFromAPI);
      console.log("Dealer Name :", dealerNameFormAPI);

      const dealerIdFromAPI = response.data.data[0].Companies[0].Companies.id;
      //   console.log(dealerIdFromAPI)
      // setDealerId(dealerIdFromAPI);
      navigate("/note", { state: { contactId, dealerId: dealerIdFromAPI } });
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };

  useEffect(() => {
    if (dealerId) {
      console.log(contactId);
      console.log(dealerId);
      // Navigate with the updated dealerId
      navigate("/note", { state: { contactId, dealerId } });
    }
  }, [dealerId]);

  const handleRejectClick = () => {
    setIncomingCall(false);
  };

  const handleNextClick = () => {
    setCurrentDealerIndex((prevIndex) => {
      const dealerIndex = (prevIndex + 1) % jsonData.Dealers.length || 0;
      localStorage.setItem("call-screen-info-dealer-index", dealerIndex);

      if (window.RC.status.call.isLoopCalls && dealerIndex != 0) {
        const isPreferedCalls = window.RC.status.call.isPreferedCalls;
        window.RC.status.call.pauseLoopCalls();
        window.RC.status.call.resetCallingIndex();
        const currentCopy = jsonData.Dealers[dealerIndex];
        console.log(currentCopy, "Xxx");
        window.RC.fn.handleDealerContactsLoopCall(
          currentCopy,
          handleContactClick,
          jsonData.vehicle_info.id,
          currentCopy.lead_info.id,
          handleNextClick,
          isPreferedCalls
        );
      } else {
        window.RC.status.call.pauseLoopCalls();
      }
      return dealerIndex;
    });
  };

  const handlePrevClick = () => {
    window.RC.status.call.pauseLoopCalls();
    setCurrentDealerIndex((prevIndex) => {
      localStorage.setItem(
        "call-screen-info-dealer-index",
        (prevIndex - 1 + jsonData.Dealers.length) % jsonData.Dealers.length || 0
      );
      return (
        (prevIndex - 1 + jsonData.Dealers.length) % jsonData.Dealers.length || 0
      );
    });
  };

  const handleContactClick = (contactId, dealerId, vehicleId, leadId) => {
    navigate("/note", { state: { contactId, dealerId, vehicleId, leadId } });
  };

  return (
    <>
      <div className="container1 col-7">
        <div className="car-container">
          {/* {incomingCall && (
          <div className="incoming_call_main text-center">
            <div className="phone_number">{phoneNumber}</div>
            <div className="call_btns">
              <button className="btn btn-danger" onClick={handleRejectClick}>
                Reject
              </button>
              <button className="btn btn-success" onClick={handleAcceptClick}>
                Accept
              </button>
            </div>
          </div>
        )} */}
          {/* {incomingCall && (
            <div className="receving-call-container2">
              <div className="receving-call">
                <div className="name">
                  <div className="phone_number">Numberzzz: {phoneNumber}</div>
                  <div className="l1">Contact: {contact.Full_Name} </div>
                  <div onClick={handleAcceptClick}>
                    <div
                      className="open-new-window d-flex align-center"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <i
                        style={{
                          marginTop: "-3px",
                        }}
                        className="bi bi-box-arrow-up-right"
                      ></i>{" "}
                      Open
                    </div>
                  </div>
                </div>
                <div className="answer">
                  <i onClick={handleRejectClick} className="bi bi-x-circle"></i>
                </div>
              </div>
            </div>
          )} */}

          {!jsonData?.vehicle_info ? (
            <>
              <img
                src="/images/empty.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRight: "0.5px solid #cecece",
                }}
                alt=""
                srcset=""
              />
            </>
          ) : (
            <>
              <div className="header_car_and_bids d-flex justify-content-between align-items-center mt-2">
                <div className="header_car_and_bids_txt">Car & Bids</div>
                <div className="header_car_and_bids_navs">
                  <span>
                    <img
                      onClick={() => {
                        try {
                          window.ZOHO.CRM.UI.Dialer.minimize();
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      src="/img/close-circle.svg"
                      className="header_car_and_bids_navs_btn"
                      alt=""
                    />
                  </span>
                </div>
              </div>
              <div className="car_and_name">
                <div className="car_img">
                  <img
                    className="inner_car_img"
                    src={jsonData.vehicle_info.image}
                    alt="car_image"
                  />
                </div>

                <div className="info_main_side d-flex flex-column">
                  <div className="car_name">
                    <div
                      className="car_name_text"
                      title={jsonData.vehicle_info.name}
                    >
                      {jsonData.vehicle_info.name}
                    </div>
                  </div>

                  {currentDealer?.contacts?.length && (
                    <div className="mt-2">
                      {/* {window.RC.status.call.isLoopCalls ? (
                        <span
                          style={{ cursor: "pointer" }}
                          className="mr-2 badge text-bg-warning"
                          onClick={function() {
                            window.RC.fn.handleDealerContactsLoopCall(
                              currentDealer,
                              handleContactClick,
                              jsonData.vehicle_info.id,
                              currentDealer.lead_info.id
                            );
                          }}
                        >
                          <i
                            style={{ fontSize: "11px", marginRight: "5px" }}
                            className="bi bi-pause-circle-fill"
                          ></i>
                          Pause
                        </span>
                      ) : (
                        <>
                          <span
                            style={{ cursor: "pointer" }}
                            className="mr-2 badge text-bg-success"
                            onClick={function() {
                              window.RC.fn.handleDealerContactsLoopCall(
                                currentDealer,
                                handleContactClick,
                                jsonData.vehicle_info.id,
                                currentDealer.lead_info.id,
                                handleNextClick
                              );
                            }}
                          >
                            <i
                              style={{ fontSize: "11px", marginRight: "5px" }}
                              className="bi bi-telephone"
                            ></i>
                            Call
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            className="mx-2 badge text-bg-warning"
                            onClick={function() {
                              window.RC.fn.handleDealerContactsLoopCall(
                                currentDealer,
                                handleContactClick,
                                jsonData.vehicle_info.id,
                                currentDealer.lead_info.id,
                                handleNextClick,
                                true
                              );
                            }}
                          >
                            <i
                              style={{ fontSize: "11px", marginRight: "5px" }}
                              className="bi bi-telephone"
                            ></i>
                            Preferd Call
                          </span>
                        </>
                      )} */}

                      <button
                        className="img-button"
                        onClick={() => {
                          window.ZOHO.CRM.UI.Record.open({
                            Entity: "Vehicles",
                            RecordID: jsonData.vehicle_info.id,
                          }).then(function(data) {
                            console.log(data);
                          });
                        }}
                      >
                        <img
                          src="/img/money-send.svg"
                          style={{ width: "18px" }}
                          alt=""
                        />{" "}
                        Open
                      </button>
                    </div>
                  )}
                </div>

                <div className="carandname_for_call d-flex justify-content-center align-items-center">
                  {window.RC.status.call.isLoopCalls ? (
                    <span
                      style={{ cursor: "pointer" }}
                      className="mr-2 badge text-bg-warning"
                      onClick={function() {
                        window.RC.fn.handleDealerContactsLoopCall(
                          currentDealer,
                          handleContactClick,
                          jsonData.vehicle_info.id,
                          currentDealer.lead_info.id
                        );
                      }}
                    >
                      <i
                        style={{ fontSize: "11px", marginRight: "5px" }}
                        className="bi bi-pause-circle-fill"
                      ></i>
                      Pause
                    </span>
                  ) : (
                    <>
                      <span
                        style={{ cursor: "pointer", "border-radius": "50%" }}
                        className="mr-2 badge "
                        onClick={function() {
                          window.RC.fn.handleDealerContactsLoopCall(
                            currentDealer,
                            handleContactClick,
                            jsonData.vehicle_info.id,
                            currentDealer.lead_info.id,
                            handleNextClick
                          );
                        }}
                      >
                        <img src="/img/Dailer_1.png" alt="" />
                      </span>
                      {/* <span
                            style={{ cursor: "pointer" }}
                            className="mx-2 badge text-bg-warning"
                            onClick={function() {
                              window.RC.fn.handleDealerContactsLoopCall(
                                currentDealer,
                                handleContactClick,
                                jsonData.vehicle_info.id,
                                currentDealer.lead_info.id,
                                handleNextClick,
                                true
                              );
                            }}
                          >
                            <i
                              style={{ fontSize: "11px", marginRight: "5px" }}
                              className="bi bi-telephone"
                            ></i>
                            Preferd Call
                          </span> */}
                    </>
                  )}
                </div>
              </div>
              <div className="Dealer_slider d-flex align-items-center">
                <div
                  className="Dealer_name cst-hover"
                  onClick={() => {
                    window.ZOHO.CRM.UI.Record.open({
                      Entity: "Accounts",
                      RecordID: currentDealer?.id,
                    }).then(function(data) {
                      console.log(data);
                    });
                  }}
                >
                  {currentDealerIndex + 1} : {currentDealer?.name}
                </div>

                <div className="dealer_name_nav-btn ms-4 d-flex">
                  <div
                    className="prev sbtn me-3"
                    alt="prev"
                    onClick={handlePrevClick}
                  >
                    <i class="bi bi-chevron-left"></i>
                  </div>

                  <div
                    className="next sbtn "
                    alt="next"
                    onClick={handleNextClick}
                  >
                    <i class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
              <div className="contacts_container">
                <div className="contacts">
                  {currentDealer?.contacts?.map((contact, index) => (
                    <div className="link_note" key={index}>
                      <div className="contact_row">
                        <div className="contact_img">
                          <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="18"
                              cy="18"
                              r="17.5"
                              fill="#F7F7F7"
                              // stroke="#AEAEAE"
                            />
                            <text
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dy=".3em"
                              fontSize="16"
                              fontWeight={700}
                              fill="#000000"
                              // fontFamily="Arial, sans-serif"
                            >
                              {contact.name.charAt(0).toUpperCase()}
                            </text>
                          </svg>
                        </div>

                        <div
                          className="name_num"
                          onClick={() => {
                            window.RC.status.call.pauseLoopCalls();
                            handleContactClick(
                              contact.id,
                              currentDealer.id,
                              jsonData.vehicle_info.id,
                              currentDealer.lead_info.id
                            );
                          }}
                        >
                          <div className="name">
                            {contact.name}
                            &nbsp;
                            {currentDealer?.info?.Call_Preference?.id ==
                              contact.id && (
                              <div className="badge text-bg-info">Prefered</div>
                            )}
                          </div>
                          <div className="number">{contact.phone}</div>
                        </div>

                        <span
                          id={"contact-" + contact.id}
                          style={{ opacity: "0" }}
                        >
                          <img
                            src="/images/icons8-call.gif"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </span>
                        <i
                          style={{
                            color: "black",
                            fontSize: "18px",
                          }}
                          className="bi bi-telephone-fill cst-hover"
                          onClick={() => {
                            window.RC.status.call.pauseLoopCalls();
                            window.UA_HandleCall(contact.phone);
                          }}
                        ></i>
                        <i
                          style={{
                            color: "black",
                            fontSize: "20px",
                          }}
                          className="icon-hover bi bi-three-dots-vertical cst-hover"
                        >
                          <div className="icon-hover-list">
                            <div className="icon-hover-items">
                              <div
                                className="icon-hover-item"
                                onClick={() => {
                                  window.ZOHO.CRM.UI.Record.open({
                                    Entity: "Contacts",
                                    RecordID: contact?.id,
                                  }).then(function(data) {
                                    console.log(data);
                                  });
                                }}
                              >
                                <div className="icon">
                                  {/* <i className="bi bi-box-arrow-up-right"></i> */}
                                  <span>
                                    <img
                                      src="/img/money_send_black.svg"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="text">Open CRM</div>
                              </div>
                              <div
                                className="icon-hover-item"
                                onClick={() => {
                                  window.RC.component.callCreate.scheduleCall(
                                    contact.phone
                                  );
                                }}
                              >
                                <div className="icon">
                                  <span>
                                    <img src="/img/clock.svg" alt="" />
                                  </span>
                                </div>
                                <div className="text">Schedule Call</div>
                              </div>
                              <div
                                className="icon-hover-item"
                                onClick={() => {
                                  window.RC.component.callCreate.logCall(
                                    contact.phone,
                                    "Outbound",
                                    `00:00`
                                  );
                                }}
                              >
                                <div className="icon">
                                  <span>
                                    <img src="/img/call-calling.svg" alt="" />
                                  </span>
                                </div>
                                <div className="text">Log Call</div>
                              </div>
                              <div
                                className="icon-hover-item"
                                onClick={() => {
                                  window.RC.status.call.contactInformation(
                                    contact.phone
                                  );
                                }}
                              >
                                <div className="icon">
                                  <span>
                                    <img src="/img/info-circle.svg" alt="" />
                                  </span>
                                </div>
                                <div className="text">Contact Information</div>
                              </div>
                            </div>
                          </div>
                        </i>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CarCaller;
