import { useContext, useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import HistoryContext from "../context/HistoryContext";

import { useStopwatch } from "react-timer-hook";
import JsSIP from "jssip";
import audioPlayer from "../components/audioplayer";
import moment, { duration } from "moment/moment";
import "moment-timezone";
import axios from "axios";

console.log("TIME ZONEE: ");
console.log(
  "TIME ZONEE: ",
  moment
    .tz("Asia/Bangkok")
    .subtract(1, "minutes")
    .format("YYYY-MM-DD HH:mm:ss")
);

function extractNumbers(inputString) {
  // Use regular expression to replace non-numeric characters with an empty string
  return inputString.replace(/[^0-9]/g, "");
}

function removeLeadingZero(num) {
  // Convert the number to a string
  let numString = num.toString();

  // Remove the leading "0" if present
  let cleanedNumString = numString.replace(/^0+/, "");

  // Convert the cleaned string back to a number
  let cleanedNum = parseInt(cleanedNumString, 10);

  return cleanedNum;
}

const useJssip = () => {
  const audioRef = useRef();
  const searchParams = new URLSearchParams(window.location.search);
  const { setHistory } = useContext(HistoryContext);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ua, setUa] = useState(null);
  const [session, setSession] = useState(null);
  const [incommingSession, setIncommingSession] = useState([]);
  const [speakerOff, setSpeakerOff] = useState(false);
  const [status, setStatus] = useState("start");
  const [reload, setReload] = useState(false);
  const [CallEndComponent, setCallEndComponent] = useState(() => () => {
    return <></>;
  });
  const { seconds, minutes, isRunning, pause, reset } = useStopwatch({
    autoStart: false,
  });

  async function* createCallLog(
    phone,
    callType = undefined,
    description = undefined,
    duration = undefined,
    startTime = undefined,
    extraInformation = undefined
  ) {
    const zohoUser = await window.ZOHO.CRM.CONFIG.getCurrentUser().then(
      function(data) {
        return data.users?.[0];
      }
    );

    console.log("FIGURE OUT NUMBER:", phone);

    const queryContact = `select Last_Name, First_Name, Full_Name
    from Contacts
    where ((Phone in ('${phone}','0${phone}','${removeLeadingZero(
      phone
    )}')) OR (Mobile in ('${phone}','0${phone}','${removeLeadingZero(phone)}')))
    order by Created_Time desc limit 1`;

    const queryLead = `select Last_Name, First_Name, Full_Name, Rating
    from Leads
    where ((Phone in ('${phone}','0${phone}','${removeLeadingZero(
      phone
    )}')) OR (Mobile in ('${phone}','0${phone}','${removeLeadingZero(phone)}')))
    order by Created_Time desc limit 1`;

    const configContact = {
      select_query: queryContact,
    };
    const configLead = {
      select_query: queryLead,
    };

    const callToIfContact = await window.ZOHO.CRM.API.coql(configContact).then(
      function(data) {
        if (data.data) {
          return data.data?.[0];
        }
        return {};
      }
    );

    const callToIfLead = await window.ZOHO.CRM.API.coql(configLead).then(
      function(data) {
        if (data.data) {
          return data.data?.[0];
        }
        return {};
      }
    );
    let contactInfo;
    if (callToIfContact.id) {
      contactInfo = {
        type: "Contact",
        data: { ...callToIfContact, phone: phoneNumber },
      };
    } else if (callToIfLead.id) {
      contactInfo = {
        type: "Lead",
        data: { ...callToIfLead, phone: phoneNumber },
      };
    } else {
      contactInfo = {
        type: "New",
        data: {
          Full_Name: "Unknown " + phone,
          phone: phoneNumber,
        },
      };
    }
    if (callToIfContact.id) {
      [callType, description, duration, startTime, extraInformation] = yield {
        type: "Contact",
        data: { ...callToIfContact, phone: phoneNumber },
      };
    } else if (callToIfLead.id) {
      [callType, description, duration, startTime, extraInformation] = yield {
        type: "Lead",
        data: { ...callToIfLead, phone: phoneNumber },
      };
    } else {
      [callType, description, duration, startTime, extraInformation] = yield {
        type: "New",
        data: {
          Full_Name: "Unknown " + phone,
          phone: phoneNumber,
        },
      };
    }

    console.log("ZOHO USER", zohoUser, callToIfContact, callToIfLead);
    console.log("ZOHO USER xxxxxxxxx", contactInfo);

    const currentTime = moment();

    const updateLeadOwner = false;
    const leadOwnerInfo = {};

    // Subtract minutes and seconds from the current time
    let insterRecordCall;
    if (callType == "Inbound") {
      // Outgoing_Call_Status: "Completed",
      // Call_Agenda: "Fahim Test",
      // Who_Id: contactInfo.data.id,
      insterRecordCall = {
        Subject: "Incoming call from " + contactInfo.data.Full_Name,
        Owner: zohoUser.id,
        Call_Type: callType,
        Call_Duration: `${duration}`,
        Call_Start_Time: startTime,
        Description: description,
        dst1: queryPhoneNumber,
        dst: queryPhoneNumber,
      };
      if (contactInfo.type == "Contact") {
        insterRecordCall["Who_Id"] = contactInfo.data.id;
      } else if (contactInfo.type == "Lead") {
        insterRecordCall["What_Id"] = contactInfo.data.id;
        // insterRecordCall["Who_Id"] = contactInfo.data.id;
        insterRecordCall["$se_module"] = "Leads";
        console.log("Updating Lead owner and Ratin", contactInfo.data);

        if (
          contactInfo.data.Rating == "Inactive" ||
          contactInfo.data.Rating == "Shut Down" ||
          contactInfo.data.Rating == "Request activation"
        ) {
          if (
            contactInfo.data.Rating == "Inactive" ||
            contactInfo.data.Rating == "Shut Down"
          ) {
            var config = {
              Entity: "Leads",
              APIData: {
                id: contactInfo.data.id,
                Rating: "active",
                Owner: zohoUser.id,
              },
              Trigger: ["workflow"],
            };

            var config2 = {
              url: "https://www.zohoapis.com/crm/v3/Leads",
              method: "put",
              data: {
                data: [
                  {
                    id: contactInfo.data.id,
                    Rating: "active",
                    Owner: zohoUser.id,
                  },
                ],
              },
            };
            console.log("CONFIG");
            axios
              .post("https://asolar.shyamalclinic.com/proxy-auth", config2)
              .then(function(data) {
                console.log("Lead Owner and Ratting updated");
              });

            // window.ZOHO.CRM.API.updateRecord(config).then(function(data) {
            //   console.log("Lead Owner and Ratting updated");
            // });
          } else {
            var BlueprintData = {
              blueprint: [
                {
                  transition_id: "5850700000016158298",
                  data: {
                    Owner: zohoUser.id,
                  },
                },
              ],
            };

            var config = {
              Entity: "Leads",
              RecordID: contactInfo.data.id,
              BlueprintData: BlueprintData,
            };

            var config2 = {
              url: `https://www.zohoapis.com/crm/v5/Leads/${contactInfo.data.id}/actions/blueprint`,
              method: "put",
              data: BlueprintData,
            };

            axios
              .post("https://asolar.shyamalclinic.com/proxy-auth", config2)
              .then(function(data) {
                console.log("Lead Owner and Ratting updated with blueprit");
              });
            // window.ZOHO.CRM.API.updateBluePrint(config).then(function(data) {
            //   console.log("Lead Owner and Ratting updated");
            //   console.log(data);
            // });
          }
        }
        // "$se_module" : "Calls"
      } else {
        insterRecordCall["Who_Id"] = contactInfo.data.id;
      }
    } else if (callType == "Outbound") {
      console.log("2023-12-01T13:59:00+07:00", startTime);
      console.log("OUTBOUND", removeLeadingZero(phone));
      insterRecordCall = {
        Outgoing_Call_Status: "Completed",
        // Who_Id: contactInfo.data.id,
        Subject: "Outgoing call to " + contactInfo.data.Full_Name,
        Owner: zohoUser.id,
        Call_Type: callType,
        Call_Duration: `${duration}`,
        Call_Start_Time: startTime,
        Description: description,
        dst1: removeLeadingZero(phone) + "",
        dst: removeLeadingZero(phone) + "",
      };
      if (contactInfo.type == "Contact") {
        insterRecordCall["Who_Id"] = contactInfo.data.id;
      } else if (contactInfo.type == "Lead") {
        insterRecordCall["What_Id"] = contactInfo.data.id;
        // insterRecordCall["Who_Id"] = contactInfo.data.id;
        insterRecordCall["$se_module"] = "Leads";
      } else {
        insterRecordCall["Who_Id"] = contactInfo.data.id;
      }
    } else if (callType == "Missed") {
      console.log("2023-12-01T13:59:00+07:00", startTime);
      console.log("OUTBOUND", removeLeadingZero(phone));
      insterRecordCall = {
        // Who_Id: contactInfo.data.id,
        Subject:
          description +
          ` Missed call ${description == "Incoming" ? "from" : "to"} ` +
          contactInfo.data.Full_Name,
        Owner: zohoUser.id,
        Call_Type: "Missed",
        Call_Start_Time: startTime,
        Description: description,
      };
      if (contactInfo.type == "Contact") {
        insterRecordCall["Who_Id"] = contactInfo.data.id;
      } else if (contactInfo.type == "Lead") {
        insterRecordCall["What_Id"] = contactInfo.data.id;
        // insterRecordCall["Who_Id"] = contactInfo.data.id;
        insterRecordCall["$se_module"] = "Leads";
      } else {
        insterRecordCall["Who_Id"] = contactInfo.data.id;
      }
    }

    console.log("Insert Data", insterRecordCall);

    window.ZOHO.CRM.API.insertRecord({
      Entity: "Calls",
      APIData: insterRecordCall,
      Trigger: [],
    }).then(function(data) {
      console.log("Insert Record success", data);
      try {
        console.log("Getting call recording...");
        console.log("Call log ID: ", data.data[0].details.id);
        const callLogId = data.data[0].details.id;
        axios.get(
          "https://asolar.shyamalclinic.com/call-recording/" + callLogId
        );
      } catch (err) {
        console.log(err);
      }
    });
    yield {};
  }

  let socketStatus;

  window.secondTime = seconds;
  window.minuteTime = minutes;

  const configurationLocalStorageSIP = window.RC.sipProvision.sipInfo;

  const [queryPhoneNumber, queryPhonePassword] = [
    configurationLocalStorageSIP.phone || "2832",
    configurationLocalStorageSIP.password || "0a1c4a5bdf95b5b546c690625bb93739",
  ];

  // console.log(queryPhoneNumber, "PHONE NUMBER");

  // window.ZOHO.CRM.UI.Record.create({Entity:"Calls"})
  // .then(function(data){
  //   console.log(data)
  //   ZOHO.CRM.UI.Record.populate({Description:"Populating test data", "Call_Duration": "15:10" })
  //   .then(function(data){
  //       console.log(data)
  //   })
  // })

  function accepetCall(e) {
    console.log("Event: ==>", e);
    setSession(e.e.session);
    window.incommingLastCall = e?.e?.request?.from?._uri?.user;
    setPhoneNumber(e?.e?.request?.from?._uri?.user);
    e.e.session.answer(options);

    // window.RC.status.call.type = "INCOMMING";
    reset();
    setStatus("calling");
    e.e.session.connection.addEventListener("addstream", (event) => {
      console.log("CALLEDD==============>TEST1");
      audioRef.current.srcObject = event.stream;
    });
    console.log("CALLEDD==============>TEST");

    socketStatus?.emit("statusChange", {
      number: queryPhoneNumber,
      status: "oncall",
    });
  }

  async function rejectCall(e) {
    console.log(
      "REJECT CALL-------------------------------",
      e?.e?.request?.from?._uri?.user
    );
    setIncommingSession(incommingSession.filter((i) => i.id != e.id));
    e.e.session.terminate();

    // e.session.answer(options);
    // reset();
    // setStatus("calling");

    socketStatus?.emit("statusChange", {
      number: queryPhoneNumber,
      status: "connected",
    });
  }
  var eventHandlers = {
    failed: async function(e) {
      window.RC.status.call.failed();
      console.log(
        "===============FAILED===============",
        arguments,
        "Phone: ",
        phoneNumber
      );
      const phoneCopy = phoneNumber || window.incommingLastCall;
      setStatus("fail");
      setPhoneNumber("");
      setHistory((prev) => [
        ...prev.slice(0, -1),
        { ...prev[prev.length - 1], status: "Fail", start: 0, end: 0 },
      ]);
      setSession(null);

      socketStatus?.emit("statusChange", {
        number: queryPhoneNumber,
        status: "connected",
      });
      console.log(
        e?.e?.request?.from?._display_name,
        e?.message?.to?._uri?._user,
        "END===>"
      );
      if (e?.message?.to?._uri?._user) {
        window.RC.component.callCreate.logCall(
          extractNumbers(e?.message?.to?._uri?._user),
          "Missed",
          `00:00`
        );
      } else if (e?.cause == "Canceled") {
        window.RC.component.callCreate.logCall(
          extractNumbers(phoneCopy),
          "Missed",
          `00:00`
        );
      }
    },

    ended: async function(e) {
      window.RC.status.call.ended();
      console.log("===============ENDED===============");
      setHistory((prev) => [
        ...prev.slice(0, -1),
        { ...prev[prev.length - 1], end: new Date().getTime() },
      ]);
      let copyPhoneNumber = phoneNumber || window.incommingLastCall;
      pause();
      setStatus("start");
      setPhoneNumber("");
      setSession(null);

      socketStatus?.emit("statusChange", {
        number: queryPhoneNumber,
        status: "connected",
      });

      try {
        const minLatest = window.minuteTime;
        const secLatest = window.secondTime;

        console.log("Duration POPULATE: ", minLatest, ":", secLatest);
        const currentTime = moment();

        // Define the number of minutes and seconds to subtract
        const minutesToSubtract = minLatest;
        const secondsToSubtract = secLatest;

        // Subtract minutes and seconds from the current time
        const newTime = currentTime.subtract({
          minutes: minutesToSubtract,
          seconds: secondsToSubtract,
        });

        console.log(
          "<-------------------------------CALL END OUTBOUND------------------------------->",
          copyPhoneNumber,
          minLatest,
          secLatest
        );

        window.RC.component.callCreate.logCall(
          copyPhoneNumber,
          "Outbound",
          `${minLatest < 10 ? `0${minLatest}` : minLatest}:${
            secLatest < 10 ? `0${secLatest}` : secLatest
          }`
        );
      } catch (err) {
        console.log(err);
      } finally {
        console.log("<============= CALL ENDED =============>");
      }
    },

    confirmed: function(e) {
      window.RC.status.call.accepted();
      console.log("===============CONFIRM===============");
      reset();
      setHistory((prev) => [
        ...prev.slice(0, -1),
        {
          ...prev[prev.length - 1],
          status: "Success",
          start: new Date().getTime(),
        },
      ]);

      socketStatus?.emit("statusChange", {
        number: queryPhoneNumber,
        status: "oncall",
      });
    },
  };

  var options = {
    eventHandlers: eventHandlers,
    mediaConstraints: { audio: true, video: false },
  };

  let timeoutId;
  let shouldCancelTimeout = false;

  async function onetimeLoad() {
    // socketStatus = socketStatus.connect("http://localhost:3001");

    /* Inbound Call */
    /* (async () => {
      
    })() */
    /* OUTBOUND CALL */
    /* (async () => {
      
    })() */

    try {
      // console.log("CALLED====>")
      let ZohoUserResponse;
      try {
        ZohoUserResponse = await window?.ZOHO?.CRM?.CONFIG?.getCurrentUser();
      } catch (err) {
        console.log(err, "ERROR");
      }
      // const ZohoUserResponse = {};
      // console.log("CALLED====>2")
      console.log("Zoho User Info Response", ZohoUserResponse);
      let ZohoUser;

      if (ZohoUserResponse?.users?.[0]) {
        console.log("Auth User");
        ZohoUser = ZohoUserResponse?.users?.[0];
      } else {
        ZohoUser = { id: Math.floor(Math.random() * 100000000) };
        console.log("Dummy User");
      }

      console.log("Zoho User Info: ", ZohoUser);

      // const userInfo = ZohoUser.users[0];
      // console.log("CALLED====>")
      // const ZohoUser = await window.ZOHO.CRM.CONFIG.getCurrentUser();
      // console.log("CALLED====>2")

      // const userInfo = {id: "4526809000000111001"};

      const SIP_INFO = window.RC.sipProvision.sipInfo;

      var socket = new JsSIP.WebSocketInterface(
        `wss://${SIP_INFO.outboundProxy}`
      );
      var configuration = {
        sockets: [socket],
        uri: `sip:${SIP_INFO.username}@${SIP_INFO.domain}`,
        authorization_user: SIP_INFO.authorizationId,
        password: SIP_INFO.password,
        trace_sip: true,
        stun_servers: SIP_INFO.stunServers.map((server) => `stun:${server}`),
        trace_sip: true,
        log: {
          level: 1,
        },
        autostart: true,
        register: true,
        onRegisterFailed: function(e) {
          console.log("On Register");
          // if (!isUserAuthenticated(this.configuration.authorization_user, this.configuration.password)) {
          //   e.reject(); // Reject registration if authentication fails
          // }
        },
      };
      console.log(configuration, "CONFIG");
      var ua = new JsSIP.UA(configuration);
      // ua.
      window.UA = ua;
      ua.start();

      // socketStatus = new window.io("ws://localhost:3001", {
      //   query: {
      //     "phone": queryPhoneNumber
      //   }
      // });

      ua.on("connecting", () => {
        setConnectionStatus("connecting");
      });

      ua.on("connected", () => {
        console.log("connected ==");
        setConnectionStatus("connected");

        /***
         * THIS IS WHERE WEBSCOKET CONNECTION CODE PAST
         */
        socketStatus = new window.io("wss://asolar.shyamalclinic.com", {
          query: {
            phone: queryPhoneNumber,
            zoho_user_id: ZohoUser.id || "123",
          },
        });
      });

      ua.on("disconnected", () => {
        console.log("D(()()()()()(()()()");
        setConnectionStatus("disconnected");
        socketStatus?.emit("statusChange", {
          number: queryPhoneNumber,
          status: "disconnected",
        });
      });

      function displayErrorMessage(message) {
        const errorContainer = document.getElementById("error-message");
        errorContainer.textContent = message;
        errorContainer.style.display = "block";
      }

      ua.on("newRTCSession", function(e) {
        function dontCalled() {
          console.log(
            "dontCalled has been called. Cancelling setTimeout if scheduled."
          );
          clearTimeout(timeoutId); // Cancel the timeout if it has been scheduled
        }

        dontCalled();

        const styleDebug = `color: black; background-color: yellow; border-radius: 5px; paddin: 0.5rem`;
        console.log("%c Debug", styleDebug);
        console.log("New Session management", e);
        if (e.originator == "remote") {
          socketStatus?.emit("statusChange", {
            number: queryPhoneNumber,
            status: "oncall",
          });
          try {
            window.ZOHO.CRM.UI.Dialer.maximize();
            window.ZOHO.CRM.UI.Dialer.notify();
          } catch (err) {
            console.log(err);
          }
          console.log("Answered ===>", e);

          audioPlayer.play("ringing");
          const id = Math.floor(new Date().getTime() * Math.random());
          let x = [
            ...incommingSession,
            {
              id: id,
              e: e,
            },
          ];
          setIncommingSession(x);
          // e.session.answer(options);
          // reset();
          // setStatus("calling");

          console.log(incommingSession, "incommingSession", {
            id: id,
            e: e,
          });

          e.session.on("failed", async () => {
            const locEvent = arguments?.[0];
            console.error("Session failed:", locEvent);
            if (locEvent.cause) {
              console.error("Failure cause:", locEvent.cause);
            } else {
              console.error("No specific cause provided for failure.");
            }
            console.log(
              "===============FAILED RECIEVE===============",
              arguments
            );
            console.log("THis", locEvent, arguments[0]);
            socketStatus?.emit("statusChange", {
              number: queryPhoneNumber,
              status: "connected",
            });
            setIncommingSession(incommingSession.filter((i) => i.id != id));
            audioPlayer.stop("ringing");
            setStatus("fail");
            setPhoneNumber("");
            setHistory((prev) => [
              ...prev.slice(0, -1),
              { ...prev[prev.length - 1], status: "Fail", start: 0, end: 0 },
            ]);
            setSession(null);

            async function executeSetTimeout() {
              timeoutId = setTimeout(async () => {
                if (!shouldCancelTimeout) {
                  console.log("setTimeout function executed.");

                  // const createCallLogGenrator = createCallLog(
                  //   extractNumbers(locEvent?.request?.from?._display_name)
                  // );
                  // const callInformation = await createCallLogGenrator.next();
                  // createCallLogGenrator.next([
                  //   "Missed",
                  //   "Incoming" || "",
                  //   `00:00`,
                  //   moment
                  //     .tz("Asia/Bangkok")
                  //     .subtract(1, "minutes")
                  //     .format("yyyy-MM-DDTHH:mm:ss+07:00"),
                  //   {},
                  // ]);
                  console.log(locEvent?.request?.from?._display_name, "CUT the call");
                  window.RC.component.callCreate.logCall(
                    extractNumbers((locEvent?.request?.from?._uri?.user || "")),
                    "Missed",
                    `00:00`
                  );
                } else {
                  console.log(
                    "setTimeout function was prevented from execution."
                  );
                }
              }, 3000);
            }

            executeSetTimeout();

            console.log(
              "REJECT OR FAILED TO RECIEVE",
              locEvent,
              locEvent?.request?.from?._display_name
            );
          });

          e.session.on("terminated", function(e) {
            console.log(
              "--------------->Session terminated<---------------",
              e
            );
          });

          e.session.on("ended", async () => {
            console.log("===============ENDED RECIEVE===============");
            socketStatus?.emit("statusChange", {
              number: queryPhoneNumber,
              status: "connected",
            });
            setIncommingSession(incommingSession.filter((i) => i.id != id));
            setHistory((prev) => [
              ...prev.slice(0, -1),
              { ...prev[prev.length - 1], end: new Date().getTime() },
            ]);

            const copyPhoneNumber = window.incommingLastCall;
            console.log(
              "ENDENDD +++",
              copyPhoneNumber,
              window.incommingLastCall
            );

            pause();
            setStatus("start");
            setPhoneNumber("");
            setSession(null);

            try {
              const minLatest = window.minuteTime;
              const secLatest = window.secondTime;

              console.log("Duration POPULATE: ", minLatest, ":", secLatest);
              const currentTime = moment();

              // Define the number of minutes and seconds to subtract
              const minutesToSubtract = minLatest;
              const secondsToSubtract = secLatest;

              // Subtract minutes and seconds from the current time
              const newTime = currentTime.subtract({
                minutes: minutesToSubtract,
                seconds: secondsToSubtract,
              });

              console.log(
                "<-------------------------------CALL END INBOUND------------------------------->",
                minLatest,
                secLatest
              );

              window.RC.component.callCreate.logCall(
                extractNumbers(copyPhoneNumber),
                "Inbound",
                `${minLatest < 10 ? `0${minLatest}` : minLatest}:${
                  secLatest < 10 ? `0${secLatest}` : secLatest
                }`
              );

            } catch (err) {
              console.log(err);
            } finally {
              console.log("<============= CALL ENDED =============>");
            }
            console.log("<============= CALL ENDED 2 =============>");
          });

          e.session.on("accepted", () => {
            console.log("===============ACCEPTED RECIEVE===============");
            setIncommingSession(incommingSession.filter((i) => i.id != id));
            reset();
            setHistory((prev) => [
              ...prev.slice(0, -1),
              {
                ...prev[prev.length - 1],
                status: "Success",
                start: new Date().getTime(),
              },
            ]);
          });
        } else {
          setSession(e.session);
          e.session.on("failed", () => {
            console.log("TESTING ONLY");
            socketStatus?.emit("statusChange", {
              number: queryPhoneNumber,
              status: "connected",
            });
          });

          e.session.on("ended", () => {
            socketStatus?.emit("statusChange", {
              number: queryPhoneNumber,
              status: "connected",
            });
          });
          socketStatus?.emit("statusChange", {
            number: queryPhoneNumber,
            status: "oncall",
          });
          e.session.connection.addEventListener("addstream", (event) => {
            audioRef.current.srcObject = event.stream;
          });
        }
      });

      setUa(ua);
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(function() {
    window.$(document).ready(function() {
      axios
        .get(
          `https://asolar.shyamalclinic.com/connection-status?phone=${queryPhoneNumber}`
        )
        .then((res) => {
          console.log("Connection status", res.data.status);
          if (
            res.data.status == "disconnected" ||
            res.data.status == "" ||
            res.data.status == null
          ) {
            onetimeLoad();
          } else {
            // window.$("#session-present").css("display", "flex")
          }
        });

      // setTimeout(() => {
      // }, 2000)
    });
  }, []);

  // useEffect(() => {
  //   try {
  //     console.log("New Session management")
  //     ua.on('newRTCSession', (data) => {
  //       // if (!this._mounted)
  //       //   return;

  //       console.log("Session Added")

  //       // TODO: For testing.
  //       window.SESSION = data.session;

  //       if (data.originator === 'local')
  //         return;

  //       // logger.debug('UA "newRTCSession" event');

  //       // const state = this.state;
  //       // const session = data.session;

  //       // // Avoid if busy or other incoming
  //       // if (state.session || state.incomingSession) {
  //       //   // logger.debug('incoming call replied with 486 "Busy Here"');

  //       //   session.terminate(
  //       //     {
  //       //       'status_code': 486,
  //       //       'reason_phrase': 'Busy Here'
  //       //     });

  //       //   return;
  //       // }

  //       // audioPlayer.play('ringing');
  //       // this.setState({ incomingSession: session });

  //       // session.on('failed', () => {
  //       //   // audioPlayer.stop('ringing');
  //       //   this.setState(
  //       //     {
  //       //       session: null,
  //       //       incomingSession: null
  //       //     });
  //       // });

  //       // session.on('ended', () => {
  //       //   this.setState(
  //       //     {
  //       //       session: null,
  //       //       incomingSession: null
  //       //     });
  //       // });

  //       // session.on('accepted', () => {
  //       //   // audioPlayer.stop('ringing');
  //       //   this.setState(
  //       //     {
  //       //       session: session,
  //       //       incomingSession: null
  //       //     });
  //       // });
  //     });

  //   } catch (e) {
  //     console.error(e)
  //   }
  // }, [])

  const handleCall = (num = "") => {
    window.incommingLastCall = num;
    setSpeakerOff(false);
    if (num || phoneNumber) {
      if (num) {
        setPhoneNumber(num);
      }
      setHistory((prev) => [
        ...prev,
        {
          startTime: new Date(),
          phoneNumber: num || phoneNumber,
        },
      ]);

      window.RC.status.call.outgoingCall((num || phoneNumber).replace(" ", ""));

      ua.call((num || phoneNumber).replace(" ", ""), options);
      setStatus("calling");
    }
  };

  window.UA_HandleCall = handleCall;

  return [
    connectionStatus,
    seconds,
    minutes,
    status,
    phoneNumber,
    setPhoneNumber,
    handleCall,
    session,
    speakerOff,
    setSpeakerOff,
    isRunning,
    audioRef,
    incommingSession,
    accepetCall,
    rejectCall,
    queryPhoneNumber,
    queryPhonePassword,
    CallEndComponent,
  ];
};

export default useJssip;
