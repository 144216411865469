import "./call-screen.scss";
import { BsPersonFill, BsMicMute, BsMic } from "react-icons/bs";
import { IoIosKeypad } from "react-icons/io";
import {
  IoCloseCircleOutline,
  IoCloseCircle,
  IoVolumeMuteSharp,
  IoVolumeHigh,
} from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";
import { ImPhoneHangUp } from "react-icons/im";
import useFormatPhoneNumber from "./../../hooks/useFormatPhoneNumber";
import { useEffect, useState } from "react";
import KeyPad from "./../key-pad/KeyPad";

function extractNumbers(inputString) {
  // Use regular expression to replace non-numeric characters with an empty string
  return inputString.replace(/[^0-9]/g, "");
}

function removeLeadingZero(num) {
  // Convert the number to a string
  let numString = num.toString();

  // Remove the leading "0" if present
  let cleanedNumString = numString.replace(/^0+/, "");

  // Convert the cleaned string back to a number
  let cleanedNum = parseInt(cleanedNumString, 10);

  return cleanedNum;
}

let contactInfoGenerator;
let contactInfo;
const CallScreen = ({
  renderName,
  phoneNumber,
  session,
  speakerOff,
  setSpeakerOff,
  seconds,
  minutes,
  isRunning,
}) => {
  const [currNum, setCurrNum] = useState("");
  const [numberType, setNumberType] = useState("DMTF"); // TRANSFER
  const [isHovered, setIsHovered] = useState(false);
  const [showKeyPad, setShowKeyPad] = useState(false);
  const [muted, setMuted] = useState(false);
  const [reload, setReload] = useState(true);

  async function* getPhoneNumberInfo(phone) {
    console.log("FIGURE OUT NUMBER:", phone);

    const queryContact = `select Last_Name, First_Name, Full_Name
    from Contacts
    where ((Phone in ('${phone}','0${phone}','${removeLeadingZero(
      phone
    )}')) OR (Mobile in ('${phone}','0${phone}','${removeLeadingZero(phone)}')))
    order by Created_Time desc limit 1`;

    const queryLead = `select Last_Name, First_Name, Full_Name, Lead_Status
    from Leads
    where ((Phone in ('${phone}','0${phone}','${removeLeadingZero(
      phone
    )}')) OR (Mobile in ('${phone}','0${phone}','${removeLeadingZero(phone)}')))
    order by Created_Time desc limit 1`;

    const configContact = {
      select_query: queryContact,
    };
    const configLead = {
      select_query: queryLead,
    };

    const callToIfContact = await window.ZOHO.CRM.API.coql(configContact).then(
      function(data) {
        if (data.data) {
          return data.data?.[0];
        }
        return {};
      }
    );

    const callToIfLead = await window.ZOHO.CRM.API.coql(configLead).then(
      function(data) {
        if (data.data) {
          return data.data?.[0];
        }
        return {};
      }
    );
    let contactInfo;
    if (callToIfContact.id) {
      contactInfo = {
        type: "Contact",
        data: { ...callToIfContact, phone: phoneNumber },
      };
    } else if (callToIfLead.id) {
      contactInfo = {
        type: "Lead",
        data: { ...callToIfLead, phone: phoneNumber },
      };
    } else {
      contactInfo = {
        type: "New",
        data: {
          Full_Name: "Unknown",
          phone: phoneNumber,
        },
      };
    }

    console.log("Contact INFO", "Xxx");
    if (callToIfContact.id) {
      yield {
        type: "Contact",
        data: { ...callToIfContact, phone: phoneNumber },
      };
    } else if (callToIfLead.id) {
      yield { type: "Lead", data: { ...callToIfLead, phone: phoneNumber } };
    } else {
      yield {
        type: "New",
        data: {
          Full_Name: "Unknown",
          phone: phoneNumber,
        },
      };
    }

    while (true) {
      yield contactInfo;
    }
  }

  useEffect(() => {
    console.log("NEW NUMBER");
    contactInfo = {};
    setReload(!Math.random());
    contactInfoGenerator = getPhoneNumberInfo(extractNumbers(phoneNumber));
    contactInfoGenerator?.next()?.then((res) => {
      contactInfo = res;
      setReload(Math.random());
    });
  }, []);

  contactInfoGenerator?.next()?.then((res) => {
    contactInfo = res;
  });

  useEffect(() => {
    renderName();
  });

  const formatPhoneNumber = useFormatPhoneNumber();
  return (
    <div className="call-container">
      <div className="call-top">
        <div className="avatar">
          <BsPersonFill className="person-icon" />
        </div>
        {/* <div className="phone-number text-center" id={`answered-phone-number-${phoneNumber}`}></div> */}
        <div className="phone-number text-center">
          {phoneNumber /* formatPhoneNumber(phoneNumber) */}
        </div>
        {!isRunning ? (
          <span className="status">Calling...</span>
        ) : (
          <span className="status">{minutes + " : " + seconds}</span>
        )}
        {contactInfo?.value && (
          <>
            <div className="call-dialer-info">
              <div className="caller-status d-flex justify-content-center">
                <div className="caller-name">
                  {contactInfo?.value?.type == "Contact" && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        window.ZOHO.CRM.UI.Record.open({
                          Entity: "Contacts",
                          RecordID: contactInfo?.value?.data?.id,
                        }).then(function(data) {
                          console.log(data);
                        });
                      }}
                    >
                      <h6>{contactInfo?.value?.data?.Full_Name}</h6>
                    </a>
                  )}
                  {contactInfo?.value?.type == "Lead" && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        window.ZOHO.CRM.UI.Record.open({
                          Entity: "Leads",
                          RecordID: contactInfo?.value?.data?.id,
                        }).then(function(data) {
                          console.log(data);
                        });
                      }}
                    >
                      <h6>{contactInfo?.value?.data?.Full_Name}</h6>
                    </a>
                  )}
                  {contactInfo?.value?.type == "New" && (
                    <a>
                      <h6>{contactInfo?.value?.data?.Full_Name}</h6>
                    </a>
                  )}
                </div>
              </div>
              <h6 className="text-center">{contactInfo?.value?.type}</h6>

              {contactInfo?.value?.type == "New" && (
                <div className="unknown-action  d-flex justify-content-center">
                  <div
                    className="new-action-btn"
                    onClick={() => {
                      window.ZOHO.CRM.UI.Record.create({
                        Entity: "Contacts",
                      }).then((res) => {
                        // setTimeout(() => {
                        //   window.ZOHO.CRM.UI.Record.populate({ Phone: "85663655" })
                        //     .then(function (data) {
                        //       console.log(data)
                        //     })
                        // }, 4000)
                      });
                    }}
                  >
                    + Create Contact
                  </div>
                </div>
              )}
              {contactInfo?.value?.type == "Contact" && (
                <div className="unknown-action d-flex justify-content-center">
                  <div
                    className="new-action-btn"
                    onClick={() => {
                      window.RC.component.callCreate.scheduleCall(phoneNumber);
                    }}
                  >
                    <i
                      className="bi bi-clock mr-2"
                      style={{ marginRight: "5px" }}
                    ></i>{" "}
                    Schedule a Call
                  </div>
                </div>
              )}
              {contactInfo?.value?.type == "Lead" && (
                <div className="caller-status" style={{ marginTop: "10px" }}>
                  <div className="caller-name">Lead Staus</div>
                  <div className="call-dialer-type">
                    {contactInfo?.value?.data?.Lead_Status || "-"}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {/* <div className="">

          {console.log(contactInfo, "Xxx2")}
        </div> */}
      </div>
      <div className="call-bottom" style={{ width: "100%" }}>
        {!showKeyPad ? (
          <div className="actions">
            {/* <span
              className="actions-item"
              onClick={() => {
                setShowKeyPad(true);
                setNumberType("TRANSFER");
              }}
            >
              <BiTransfer className="actions-icon" />
            </span> */}
          </div>
        ) : (
          <div className="keypad show-key-pad">
            <div className="phone-number">{currNum}</div>
            <KeyPad
              setPhoneNumber={setCurrNum}
              sendDTMF={(num) => {
                if (numberType != "DMTF") return;
                console.log("DMTF num", num);
                const dtmfSender = session.connection
                  .getSenders()
                  .find((sender) => sender.dtmf);
                if (dtmfSender) {
                  dtmfSender.dtmf.insertDTMF(Number(num));
                  console.log(`Sent DTMF tone: ${Number(num)}`);
                } else {
                  console.error("DTMF sender not found");
                }

                session.sendDTMF(Number(num));
              }}
            />
            <div
              className="close-icon"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
              onClick={() => {
                setCurrNum("");
                setShowKeyPad(false);
              }}
            >
              {isHovered ? <IoCloseCircle /> : <IoCloseCircleOutline />}
            </div>

            {numberType == "TRANSFER" && (
              <div
                className="actions text-center cst-hover"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  window.create_forwarding_number_by_number();
                }}
              >
                <span
                  className="actions-item"
                  onClick={() => {
                    setNumberType("TRANSFER");
                  }}
                >
                  <BiTransfer className="actions-icon" />
                </span>
                <span>Transfer</span>
              </div>
            )}
          </div>
        )}
        <div className="actions">
          <span
            className={speakerOff ? "actions-item active" : "actions-item"}
            onClick={() => {
              setSpeakerOff(!speakerOff);
            }}
          >
            {!speakerOff ? (
              <img
                src="/img/volume-slash-no-mute.svg"
                className="actions-icon"
                alt=""
                srcset=""
              />
            ) : (
              <img
                src="/img/volume-slash.svg"
                className="actions-icon"
                alt=""
                srcset=""
              />
            )}
            <div className="action-text">Speaker</div>
          </span>
          <span
            className="actions-item"
            onClick={() => {
              window.RC.status.call.contactInformation(phoneNumber);
            }}
          >
            <img
              src="/img/profile.svg"
              className="actions-icon"
              alt=""
              srcset=""
            />
            <div className="action-text">Contact</div>
          </span>
          <span
            className={muted ? "actions-item active" : "actions-item"}
            onClick={() => {
              muted ? session.unmute() : session.mute();
              setMuted(!muted);
            }}
          >
            {muted ? (
              <img
                src="/img/microphone-2-mute.svg"
                className="actions-icon"
                alt=""
                srcset=""
              />
            ) : (
              <img
                src="/img/microphone-2.svg"
                className="actions-icon"
                alt=""
                srcset=""
              />
            )}
            <div className="action-text">Mic</div>
          </span>
        </div>
        <div className="actions mt-4">
          <span className="actions-item"></span>
          <span
            className="actions-item"
            onClick={() => {
              session.terminate();
            }}
          >
            <div className="text-center">
              <img src="/img/speaker.svg" alt="" srcset="" />
            </div>
          </span>
          <span
            className="actions-item"
            onClick={() => {
              setShowKeyPad(true);
              setNumberType("DMTF");
            }}
          >
            <img
              src="/img/keypad-new.svg"
              className="actions-icon"
              alt=""
              srcset=""
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CallScreen;
