import React, { useCallback, useEffect, useState } from "react";
// import toast, { Toaster } from "solid-toast";

import App from "./App";
import Notes_main from "./Notes_main";
import CarCaller from "./car_caller.jsx";


export default function Auth() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  function initiateLogin() {
    window.RC.login(
      window.RC.config.Server,
      window.RC.config.AppKey,
      window.RC.config.AppSecret
    );
  }

  useEffect(() => {
    if (window.RC.isLoggedIn()) {
      window.RC.initWebRTC().then((rcInfo) => {
        console.log(rcInfo);

        const sipInfo = rcInfo.sipInfo[0];

        localStorage.setItem("SIP-RC", JSON.stringify(sipInfo));

        setIsLoggedIn(true);
      });
    }
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <App></App>
      ) : (
        <>
          <div className="App">
            {/* <div>
        {connectionStatus}
      </div> */}

            <section className="main_section">
              <section className="Notes_main_1">
                <header className="d-flex justify-content-between align-items-center px-3">
                  <i
                    className="bi bi-arrow-clockwise roate-360-hover"
                    style={{
                      color: "#df251f",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.location.reload();
                    }}
                  ></i>
                  <div className="title">Cars & Bids</div>
                  <i
                    className="bi bi-dash-circle"
                    style={{
                      color: "#2ca426",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      try {
                        window.ZOHO.CRM.UI.Dialer.minimize();
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  ></i>
                </header>
                <div
                  className="p20"
                  style={{
                    height: "calc(100vh - 40px)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="mT30" style={{ textAlign: "center" }}>
                    <img src="../img/ringcentral.png" />
                  </h4>
                  <a
                    href="javascript:;"
                    className="mT30 loginTxtLink"
                    onClick={initiateLogin}
                    id="login"
                    style={{
                      display: "block",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    Login to your RingCentral account.
                  </a>
                </div>
              </section>
            </section>
          </div>
        </>
      )}

    </>
  );
}
