import React, { useEffect, useRef, useState } from "react";
import "./Notes_main.css";
import { Form, Modal, Button } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Dropdown from "./Dropdown";
import removeIcon from "./assets/remove.svg";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ThreeCircles, TailSpin } from "react-loader-spinner";
import ScheduleCall from "./components/ScheduleCall";

let TOKEN =
  localStorage.getItem("TOKEN") ||
  "1000.22e0e5cfdc3ab1cf12c43b154d092154.a0adb34b4107229155a38352644426ed";
let zCRM;

async function axiosAuth(config = {}, alreadyNewToken = false) {
  return new Promise(async function(resolve, reject) {
    try {
      if (config?.headers?.["Content-Type"] == "multipart/form-data") {
        const response = await axios.post(
          "https://nocors.nocors.shyamalclinic.com/no-cors-multer",
          config.data,
          {
            ...config,
            headers: {
              url: config.url,
              "Content-Type": "multipart/form-data",
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      } else {
        const response = await axios.post(
          "https://nocors.shyamalclinic.com/no-cors",
          {
            ...config,
            data: config.data,
            headers: {
              Authorization: `Zoho-oauthtoken ${TOKEN}`,
              ...(config?.headers || {}),
            },
          }
        );
        resolve(response);
      }
    } catch (error) {
      console.log(error, error.response?.data);
      if (
        !alreadyNewToken &&
        (error.response?.data?.code == "INVALID_TOKEN" ||
          error.response?.data?.code == "AUTHENTICATION_FAILURE")
      ) {
        // Token has expired, get a new TOKEN and make the API call again
        TOKEN = await getNewToken();
        return resolve(axiosAuth(config, true));
      } else {
        reject(error);
      }
    }
  });
}

async function getNewToken() {
  // Code to get a new token from Zoho API
  console.log("GENERATE NEW API TOKEN");
  var config = {
    method: "post",
    url:
      "https://accounts.zohocloud.ca/oauth/v2/token?refresh_token=1000.9699bf92aa733ec2c1975372242ba33d.b3b08afe449117eb594f00424df55df1&client_id=1000.7HJ1FUTLTWSZDXGQEQFXSRK71BI4AL&client_secret=5c5b48542cd5553c228ebb78aee327277010325760&grant_type=refresh_token",
    headers: {
      "cache-control": "no-cache",
      "content-type": "application/x-www-form-urlencoded",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post("https://rr.aquaorange.co.th/no_cors.php", config)
      .then(function(response) {
        console.log("TOKEN: ", response.data?.access_token);
        localStorage.setItem("TOKEN", response.data?.access_token);
        resolve(response.data?.access_token);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function Notes_main() {
  const location = useLocation();
  const { contactId, dealerId, vehicleId, leadId } = location.state || {};
  const [currentVehicleId, setCurrentVehicleId] = useState(vehicleId || null);
  const [currentLeadId, setCurrentLeadId] = useState(leadId || null);
  const [mainactiveType, setMainActiveType] = useState(
    vehicleId ? "Vehicle_Info" : "Related_Vehicles"
  );
  useEffect(() => {
    console.log("dealerID", dealerId);
    console.log("contactId", contactId);
    console.log("vehicleID", currentVehicleId);
    console.log("leadID :", currentLeadId);
  }, [dealerId, contactId, vehicleId, currentLeadId]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isButtonLoading, setButtonLoading] = useState(false);

  const [relatedVehicles, setRelatedVehicles] = useState([]);
  const [activeType, setActiveType] = useState("Vehicle");

  const [notes, setNotes] = useState({
    Vehicle: [],
    Leads: [],
    Dealer: [],
  });

  // console.log('ccc',{contactId,dealerId,vehicleId})

  // const leadId = "3384000000588050";

  // const API_ENDPOINTS = {
  //   Vehicle: `https://www.zohoapis.ca/crm/v2/Vehicles/${currentVehicleId}/Notes`,
  //   Leads: `https://www.zohoapis.ca/crm/v2/Leads/${leadId}/Notes`,
  //   Dealer: `https://www.zohoapis.ca/crm/v2/Accounts/${dealerId}/Notes`,
  // };

  const API_ENDPOINTS = {
    Leads: `https://www.zohoapis.ca/crm/v2/Leads/${currentLeadId}/Notes`,
    Dealer: `https://www.zohoapis.ca/crm/v2/Accounts/${dealerId}/Notes`,
  };

  const fetchData = async (vehicleId) => {
    try {
      if (!TOKEN) {
        TOKEN = await getNewToken();
      }

      const getDealerNotes = axiosAuth({
        method: "get",
        url: API_ENDPOINTS.Dealer,
      });

      let vehicleNotes = [];
      let accountData = {};
      if (vehicleId) {
        const getVehicleNotes = axiosAuth({
          method: "get",
          url: `https://www.zohoapis.ca/crm/v2/Vehicles/${vehicleId}/Notes`,
        });
        const getAccount = axiosAuth({
          method: "get",
          url: `https://www.zohoapis.ca/crm/v2/Vehicles/${vehicleId}`,
        });

        const [vehicleNotesRes, accountRes] = await Promise.all([
          getVehicleNotes,
          getAccount,
        ]);
        vehicleNotes = (vehicleNotesRes.data.data || []).map((item) => ({
          title: item.Note_Title,
          date: formatDate(item.Created_Time),
          description: item.Note_Content,
          id: item.id,
        }));
        accountData = accountRes.data.data[0];
      }

      const [dealerNotesRes] = await Promise.all([getDealerNotes]);

      const dealerNotes = (dealerNotesRes.data.data || []).map((item) => ({
        title: item.Note_Title,
        date: formatDate(item.Created_Time),
        description: item.Note_Content,
        id: item.id,
      }));

      let leadNotes = [];
      if (currentLeadId) {
        // Check if leadId is not null
        const getLeadNotes = axiosAuth({
          method: "get",
          url: API_ENDPOINTS.Leads,
        });
        const leadNotesRes = await getLeadNotes;
        leadNotes = (leadNotesRes.data.data || []).map((item) => ({
          title: item.Note_Title,
          date: formatDate(item.Created_Time),
          description: item.Note_Content,
          id: item.id,
        }));
      }

      setNotes((prevNotes) => ({
        ...prevNotes,
        Vehicle: vehicleNotes,
        Leads: leadNotes,
        Dealer: dealerNotes,
      }));

      setData(accountData);

      const getRelatedVehicles = axiosAuth({
        method: "get",
        url: `https://www.zohoapis.ca/crm/v6/Leads/search?criteria=buyer_id%3Aequals%3A${dealerId}`,
      });

      const relatedVehiclesRes = await getRelatedVehicles;
      setRelatedVehicles(relatedVehiclesRes.data.data || []);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(currentVehicleId);
  }, [currentVehicleId]);

  const handleVehicleClick = (id) => {
    console.log("related vehicle id", id);
    // Find the related vehicle object by id
    const clickedVehicle = relatedVehicles.find((vehicle) => vehicle.id === id);

    if (clickedVehicle) {
      const vehicleIdObject = clickedVehicle.Vehicle_id;
      console.log("Clicked Vehicle ID Object:", vehicleIdObject);
      setCurrentVehicleId(vehicleIdObject.id);
      setCurrentLeadId(id);
      setMainActiveType("Vehicle_Info");
      // Optionally, update state or perform further actions with vehicleIdObject
    } else {
      console.error("Clicked vehicle not found in related vehicles");
    }
  };

  const [noteInput, setNoteInput] = useState({
    title: "",
    description: "",
    // files: [],
  });
  const [editIndex, setEditIndex] = useState(null);
  // const fileInputRef = useRef(null);
  const formatDate = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(date).toLocaleDateString("en-GB", options);
  };
  const [errorMessages, setErrorMessages] = useState({
    Vehicle: "",
    Leads: "",
    Dealer: "",
  });

  const [show, setShow] = useState(false);
  const [noteToDeleteIndex, setNoteToDeleteIndex] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [offerValues, setOfferValues] = useState({});
  const [isOfferLoading, setisOfferLoading] = useState(false);
  const [offerVehicleValue, setOfferVehicleValue] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleOfferInputChange = (vehicleId, e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    setOfferValues((prevOfferValues) => ({
      ...prevOfferValues,
      [vehicleId]: newValue,
    }));
  };

  const OfferVehicleInfoInputChange = (e) => {
    const newofferValue = e.target.value.replace(/[^0-9]/g, "");
    setOfferVehicleValue(newofferValue);
  };

  const handleOfferClick = async (id) => {
    const offerValue = offerValues[id] || "";
    if(offerValue == "") {
      window
        ?.Toastify({
          text: "Please fill offer",
          duration: 3000,
        })
        ?.showToast();
        return
    }
    console.log("Offer Value:", offerValue);
    const clickedVehicle = relatedVehicles.find((vehicle) => vehicle.id === id);

    if (clickedVehicle && clickedVehicle.Vehicle_id) {
      setisOfferLoading(true);
      try {
        // const response = await axios.post("aaaa", {
        //   vehicleId,
        //   offer: offerVehicleValue,
        // });

        const response = await axiosAuth({
          method: "post",
          url: `https://www.zohoapis.ca/crm/v6/Offers`,
          data: {
            data: [
              {
                // Owner: window.RC.zohoUser.id,
                Account_ID: dealerId,
                Vehicle_id: clickedVehicle.Vehicle_id,
                lead_id: leadId,
                Offer: offerValue,
                Offer_Status: "SMS",
                Name: ".",
              },
            ],
          },
        });
        setisOfferLoading(false);

        window
          ?.Toastify({
            text: "Offer created",
            duration: 3000,
          })
          ?.showToast();

        console.log("Response:", response.data);
      } catch (error) {
        setisOfferLoading(false);
        window
          ?.Toastify({
            text: "Error in creating offer",
            duration: 3000,
          })
          ?.showToast();
        console.error("Error posting offer:", error);
      }
    } else {
      window
        ?.Toastify({
          text: "This lead doesn't have vehicle",
          duration: 3000,
        })
        ?.showToast();
    }
  };

  const OfferVehicleInfoClick = async () => {
    console.log("Offer Value:", offerVehicleValue);
    if (offerVehicleValue == "") {
      window
        ?.Toastify({
          text: "Please fill offer",
          duration: 3000,
        })
        ?.showToast();
      return;
    }
    setisOfferLoading(true);
    try {
      // const response = await axios.post("aaaa", {
      //   vehicleId,
      //   offer: offerVehicleValue,
      // });

      console.log(
        {
          // Owner: window.RC.zohoUser.id,
          Account_ID: dealerId,
          Vehicle_id: currentVehicleId,
          lead_id: currentLeadId,
          Offer: offerVehicleValue,
          Offer_Status: "SMS",
          Name: "x",
        },
        location.state
      );

      const response = await axiosAuth({
        method: "post",
        url: `https://www.zohoapis.ca/crm/v6/Offers`,
        data: {
          data: [
            {
              // Owner: window.RC.zohoUser.id,
              Account_ID: dealerId,
              Vehicle_id: currentVehicleId,
              lead_id: currentLeadId,
              Offer: offerVehicleValue,
              Offer_Status: "SMS",
              Name: "x",
            },
          ],
        },
      });
      setisOfferLoading(false);

      window
        ?.Toastify({
          text: "Offer created",
          duration: 3000,
        })
        ?.showToast();

      console.log("Response:", response.data);
    } catch (error) {
      setisOfferLoading(false);
      console.error("Error posting offer:", error);
    }
  };

  const handleNoteTypeClick = (type) => {
    if (type === "Leads" && !currentLeadId) {
      setShowErrorModal(true);
    } else {
      setActiveType(type);
      setNoteInput({ title: "", description: "" });
      setEditIndex(null);
      setErrorMessages((prev) => ({ ...prev, [type]: "" }));
    }
  };

  const handleMainTypeClick = (type) => {
    if (type === "Vehicle_Info" && !currentVehicleId) {
      setShowErrorModal(true);
    } else {
      setMainActiveType(type);
    }
  };

  const closeErrorModal = () => {
    setShowErrorModal(false); // Function to close the error modal
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNoteInput({
      ...noteInput,
      [name]: value,
    });
  };

  // const handleFileChange = (e) => {
  //   setNoteInput({
  //     ...noteInput,
  //     files: [...noteInput.files, ...Array.from(e.target.files)],
  //   });
  // };

  const handleAddNote = async () => {
    if (!noteInput.description.trim()) {
      setErrorMessages((prev) => ({
        ...prev,
        [activeType]: "* Please fill up description",
      }));
      return;
    }

    const newNote = {
      Note_Title: noteInput.title,
      Note_Content: noteInput.description,
    };

    if (window?.RC?.zohoUser?.id) {
      console.log("Handle add note with owner");
      newNote.Owner = window.RC.zohoUser.id;
    }

    const requestData = {
      data: [newNote],
    };

    setButtonLoading(true);

    try {
      let url = API_ENDPOINTS[activeType];
      if (activeType === "Vehicle" && currentVehicleId) {
        url = `https://www.zohoapis.ca/crm/v2/Vehicles/${currentVehicleId}/Notes`;
      }
      const response = await axiosAuth({
        method: "post",
        url: url,
        data: requestData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log("API response:", response);

      const newNoteId = response.data.data[0]?.details.id;

      console.log("New Note ID:", newNoteId);

      if (newNoteId) {
        // Update the state with the newly added note
        setNotes((prevNotes) => ({
          ...prevNotes,
          [activeType]: [
            ...prevNotes[activeType],
            {
              title: newNote.Note_Title,
              description: newNote.Note_Content,
              date: formatDate(new Date()),
              id: newNoteId,
            },
          ],
        }));
      }

      setNoteInput({ title: "", description: "" });
      setErrorMessages((prev) => ({ ...prev, [activeType]: "" })); // Clear error message on successful add
    } catch (error) {
      console.error("Failed to add note:", error);
      // Handle the error as needed
    } finally {
      setButtonLoading(false);
    }
  };

  const handleClear = () => {
    setNoteInput({ title: "", description: "" });
  };

  const handleEditNote = (index) => {
    setEditIndex(index);
    const noteToEdit = notes[activeType][index];
    setNoteInput({
      title: noteToEdit.title,
      description: noteToEdit.description,
      // files: noteToEdit.files,
    });
    setErrorMessages((prev) => ({ ...prev, [activeType]: "" }));
  };

  const handleSaveEdit = async () => {
    if (!noteInput.description.trim()) {
      setErrorMessages((prev) => ({
        ...prev,
        [activeType]: "* Please fill up description",
      }));
      return;
    }

    const noteId = notes[activeType][editIndex].id;
    // console.log(noteId);

    const editedNote = {
      Note_Title: noteInput.title,
      Note_Content: noteInput.description,
    };
    if (window?.RC?.zohoUser?.id) {
      console.log("Handle add note with owner");
      editedNote.Owner = window.RC.zohoUser.id;
    }

    const requestData = {
      data: [editedNote],
    };

    setButtonLoading(true);

    try {
      let url = `${API_ENDPOINTS[activeType]}/${noteId}`;
      if (activeType === "Vehicle" && currentVehicleId) {
        url = `https://www.zohoapis.ca/crm/v2/Vehicles/${currentVehicleId}/Notes/${noteId}`;
      }
      const response = await axiosAuth({
        method: "put",
        url: url,
        data: requestData,
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Update the state with the edited note
      const updatedNotes = [...notes[activeType]];
      updatedNotes[editIndex] = {
        ...updatedNotes[editIndex],
        title: noteInput.title,
        description: noteInput.description,
        date: formatDate(new Date()), // Update the date to the current date
      };
      setNotes({
        ...notes,
        [activeType]: updatedNotes,
      });
      setNoteInput({ title: "", description: "" });
      setEditIndex(null);
      setErrorMessages((prev) => ({ ...prev, [activeType]: "" })); // Clear error message on successful edit
    } catch (error) {
      console.error("Failed to edit note:", error);
      // Handle the error as needed
    } finally {
      setButtonLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setNoteInput({ title: "", description: "" });
    setEditIndex(null);
  };

  const handleShow = (index) => {
    setNoteToDeleteIndex(index);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setNoteToDeleteIndex(null);
  };

  const handleDeleteNote = async () => {
    if (noteToDeleteIndex === null) return;

    const noteId = notes[activeType][noteToDeleteIndex].id;
    console.log(noteId);
    setButtonLoading(true);
    try {
      let url = `${API_ENDPOINTS[activeType]}/${noteId}`;
      if (activeType === "Vehicle" && currentVehicleId) {
        url = `https://www.zohoapis.ca/crm/v2/Vehicles/${currentVehicleId}/Notes/${noteId}`;
      }
      const response = await axiosAuth({
        method: "delete",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Update the state with the deleted note
      const updatedNotes = notes[activeType].filter(
        (_, i) => i !== noteToDeleteIndex
      );
      setNoteInput({ title: "", description: "" });
      setNotes({
        ...notes,
        [activeType]: updatedNotes,
      });
      setShow(false);
    } catch (error) {
      console.error("Failed to delete note:", error);
      // Handle the error as needed
    } finally {
      setButtonLoading(false);
    }
  };

  // const handleRemoveFile = (fileIndex) => {
  //   const updatedFiles = noteInput.files.filter(
  //     (_, index) => index !== fileIndex
  //   );
  //   setNoteInput({
  //     ...noteInput,
  //     files: updatedFiles,
  //   });
  // };

  // const handleAttachmentClick = () => {
  //   fileInputRef.current.click();
  // };

  const filteredVehicles = relatedVehicles.filter((vehicle) =>
    vehicle.Full_Name.toLowerCase().includes(searchInput.toLowerCase())
  );

  let fullName, shortDescription;
  if (data.Name) {
    [fullName, shortDescription] = data.Name.split(" - ");
  }

  return (
    <>
      {loading ? (
        <div className="col-7" style={{ width: "60&" }}>
          <Link
            to="/"
            className="note_type col-1 d-flex justify-content-center align-items-center mt-2"
          >
            <div className="back_btn_For_note_type"></div>
          </Link>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              height: "100%",
            }}
          >
            {" "}
            <TailSpin
              visible={true}
              height="80"
              width="80"
              color="rgba(0,0,0,0.8)"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </div>
      ) : (
        <section
          className="col-7 Notes_section"
          // style={{ paddingLeft: "0px" }}
        >
          <div className="Notes_main">
            <div className="header_car_and_bids d-flex justify-content-between align-items-center pt-2">
              <div className="header_car_and_bids_txt">Car & Bids</div>
              <div className="header_car_and_bids_navs">
                <span>
                </span>
                <span>
                  <img
                    onClick={() => {
                      try {
                        window.ZOHO.CRM.UI.Dialer.minimize();
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    src="/img/close-circle.svg"
                    className="header_car_and_bids_navs_btn"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <section className="Notes_type_section row">
              <Link
                to="/"
                className="note_type note_type_back_btn col-2 d-flex justify-content-center align-items-center link_for_back "
              >
                <i class="bi bi-chevron-left me-1"></i>Back
              </Link>
              <div className="main_nav_detail col-10 row ms-2">
                <div
                  className={`note_type col-4 d-flex justify-content-center align-items-center ${
                    mainactiveType === "Vehicle_Info" ? "active" : ""
                  }`}
                  onClick={() => handleMainTypeClick("Vehicle_Info")}
                >
                  Vehicle Info
                </div>
                <div
                  className={`note_type col-6 d-flex justify-content-center align-items-center ${
                    mainactiveType === "Related_Vehicles" ? "active" : ""
                  }`}
                  onClick={() => handleMainTypeClick("Related_Vehicles")}
                >
                  Related Vehicles
                </div>
                <div
                  className={`note_type col-2 d-flex justify-content-center align-items-center ${
                    mainactiveType === "Notes" ? "active" : ""
                  }`}
                  onClick={() => handleMainTypeClick("Notes")}
                >
                  Notes
                </div>
              </div>
            </section>

            {mainactiveType === "Vehicle_Info" && (
              <section className="Vehicle_body note_type_body vehicle_info_main">
                <div className="vehicle-info row">
                  <div className="vehicle-data col-7">
                    <div className="vehicle-tag">
                      <Link to="/">Home</Link>{" "}
                      <i className="bi bi-chevron-right"></i>
                      <span>Vehicle Information</span>
                    </div>
                    <div className="vehicle-name" title={fullName}>
                      {fullName}
                    </div>
                    <div className="vehicle-short-discrip">
                      {shortDescription}
                    </div>
                    <div className="open-new-window d-flex">
                      <button
                        onClick={() => {
                          window.ZOHO.CRM.UI.Record.open({
                            Entity: "Vehicles",
                            RecordID: currentVehicleId,
                          }).then(function(data) {
                            console.log(data);
                          });
                        }}
                      >
                        <img
                          src="/img/money-send.svg"
                          height="15px"
                          width="15px"
                          alt=""
                        />{" "}
                        Open
                      </button>
                    </div>
                  </div>
                  <div className="vehicle-img col-5 d-flex justify-content-end align-items-end">
                    <div className="vehicle-img-inner">
                      <img
                        src={data.Image_Link}
                        alt=""
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>

                <div className="vehicle-detailed-info">
                  <div className="row">
                    <div className="col-3">
                      <div className="">
                        <div className="title">Source</div>
                        <div className="title_value" title={data.Source}>
                          {data.Source || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <div className="title">Status</div>
                        <div className="title_value" title={data.Status}>
                          {data.Status || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <div className="title">Price</div>
                        <div className="title_value" title={data.price}>
                          {data.price || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <div className="">
                        <div className="title">Year</div>
                        <div className="title_value" title={data.Year}>
                          {data.Year || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <div className="title">Make</div>
                        <div className="title_value" title={data.Make}>
                          {data.Make || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <div className="title">Model</div>
                        <div className="title_value" title={data.Model}>
                          {data.Model || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <div className="">
                        <div className="title">Trim</div>
                        <div className="title_value" title={data.Trim}>
                          {data.Trim || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <div className="title">Mileage</div>
                        <div className="title_value" title={data.Mileage}>
                          {data.Mileage || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <div className="title">VIN</div>
                        <div className="title_value" title={data.VIN}>
                          {data.VIN || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <div className="">
                        <div className="title">VAuto</div>
                        <div className="title_value" title={data.vAuto}>
                          {data.vAuto || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="">
                        <div className="title">Carfax</div>
                        <div className="title_value" title={data.Carfax}>
                          {data.Carfax || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <div className="title">Score</div>
                        <div className="title_value" title={data.Score}>
                          {data.Score || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="">
                        <div className="title">Carfax URL</div>
                        <div
                          className="title_value"
                          title={data.Carfax_URL}
                          onClick={() => {
                            if (data.Carfax_URL) {
                              window.open(data.Carfax_URL, "_blank");
                            }
                          }}
                          style={{
                            cursor: data.Carfax_URL ? "pointer" : "default",
                          }}
                        >
                          {data.Carfax_URL || "-"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="vehicle-info-part2 d-flex offer-group ">
                    <input
                      type="text"
                      className="offer-input px-1 py-1 ps-2 me-2"
                      placeholder="Make a Offer"
                      value={offerVehicleValue}
                      onChange={OfferVehicleInfoInputChange}
                    />
                    {isOfferLoading ? (
                      <button className="offer-button">
                        <div className="spinner-border" role="status"></div>
                      </button>
                    ) : (
                      <button
                        className="offer-button"
                        onClick={() => OfferVehicleInfoClick()}
                      >
                        Offer
                      </button>
                    )}
                  </div>
                </div>
              </section>
            )}

            {mainactiveType === "Notes" && (
              <section className="Notes_body">
                <section className="col-12 notes_for_each">
                  <section className="Notes_type_section Main_Notes_type row">
                    <div
                      className={`note_type  col-3 me-2 d-flex justify-content-center align-items-center ${
                        activeType === "Vehicle" ? "active" : ""
                      }`}
                      onClick={() => handleNoteTypeClick("Vehicle")}
                    >
                      Vehicle
                    </div>
                    <div
                      className={`note_type col-3 me-2 d-flex justify-content-center align-items-center ${
                        activeType === "Leads" ? "active" : ""
                      }`}
                      onClick={() => handleNoteTypeClick("Leads")}
                    >
                      Leads
                    </div>
                    <div
                      className={`note_type col-3 d-flex justify-content-center align-items-center ${
                        activeType === "Dealer" ? "active" : ""
                      }`}
                      onClick={() => handleNoteTypeClick("Dealer")}
                    >
                      Dealer
                    </div>
                  </section>

                  {["Vehicle", "Leads", "Dealer"].map(
                    (type) =>
                      activeType === type && (
                        <>
                          <div className="note-type-opend ">
                            <span className="badge text-bg-primary"></span>
                            <button
                              className="img-button mx-2"
                              style={{ fontSize: "12px" }}
                              onClick={() => {
                                window.ZOHO.CRM.UI.Record.open({
                                  Entity:
                                    type == "Leads"
                                      ? "Leads"
                                      : type == "Vehicle"
                                      ? "Vehicles"
                                      : type == "Dealer"
                                      ? "Accounts"
                                      : "",
                                  RecordID:
                                    type == "Leads"
                                      ? leadId
                                      : type == "Vehicle"
                                      ? vehicleId
                                      : type == "Dealer"
                                      ? dealerId
                                      : "",
                                }).then(function(data) {
                                  console.log(data);
                                });
                              }}
                            >
                              <img
                                src="/img/money-send.svg"
                                style={{ width: "10px" }}
                                alt=""
                              />{" "}
                              Open
                            </button>
                          </div>
                          <section
                            key={type}
                            className={`${type}_body note_type_body note_type_main px-0 py-2`}
                          >
                            <div className="output_notes px-1">
                              {notes[activeType].map((note, index) => (
                                <div key={index} className="note">
                                  <div className="row">
                                    <div className="note_title col-10">
                                      {note.description}
                                    </div>
                                    <div className="note_buttons col-2 d-flex justify-content-between">
                                      <button
                                        onClick={() => handleEditNote(index)}
                                        className="edit_delete_btn edit_btn"
                                      ></button>
                                      <button
                                        onClick={() => {
                                          handleShow(index);
                                        }}
                                        className="edit_delete_btn delete_btn"
                                      ></button>
                                      <Modal show={show} onHide={handleClose}>
                                        <Modal.Body>
                                          Are you sure you want to delete this
                                          note?
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                          >
                                            Close
                                          </Button>
                                          <Button
                                            variant="danger"
                                            onClick={handleDeleteNote}
                                          >
                                            {isButtonLoading ? (
                                              <div className="spinner"></div>
                                            ) : (
                                              <>Delete</>
                                            )}
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </div>
                                  </div>
                                  <div className="note_date">{note.date}</div>
                                  <div className="note_description">
                                    {note.title}
                                  </div>
                                  {/* <div className="note_files d-flex flex-wrap">
                        {note.files.map((file, fileIndex) => (
                          <div
                            key={fileIndex}
                            className="attached_file px-1 ms-2 mb-1"
                          >
                            <span>{file.name}</span>
                          </div>
                        ))}
                      </div> */}
                                </div>
                              ))}
                            </div>
                            <div className="note_input_section ">
                              <div className="input_description_sec pt-1 px-2">
                                {errorMessages[activeType] && (
                                  <div className="error-message">
                                    {errorMessages[activeType]}
                                  </div>
                                )}
                                <textarea
                                  name="description"
                                  value={noteInput.description}
                                  onChange={handleInputChange}
                                  className="input_description"
                                  placeholder="Description"
                                  style={{ resize: "none" }}
                                />
                              </div>
                              <div className="row mt-1 ">
                                {/* <div className="attatch_sec col-1 d-flex justify-content-end align-items-center">
                      <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="file_input"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <button
                        onClick={handleAttachmentClick}
                        className="attatchment-btn"
                      ></button>
                    </div> */}
                                <div className="input_title_sec col-7 d-flex">
                                  <input
                                    type="text"
                                    name="title"
                                    value={noteInput.title}
                                    onChange={handleInputChange}
                                    className="Adding_title_input p-2"
                                    placeholder="Add a title"
                                  />
                                </div>
                                <div className="add_notes_buttons col-5 d-flex justify-content-end ">
                                  {editIndex !== null ? (
                                    <>
                                      <button
                                        onClick={handleSaveEdit}
                                        className="add_save_btn px-3"
                                        disabled={isButtonLoading}
                                      >
                                        {isButtonLoading ? (
                                          <div className="spinner"></div>
                                        ) : (
                                          <>Save</>
                                        )}
                                      </button>
                                      <button
                                        onClick={handleCancelEdit}
                                        className="cancel_clear_btn px-3"
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={handleAddNote}
                                        className="add_save_btn px-2"
                                        disabled={isButtonLoading}
                                      >
                                        {isButtonLoading ? (
                                          <div className="spinner"></div>
                                        ) : (
                                          <>
                                            <i className="bi bi-plus"></i>Add
                                            Note
                                          </>
                                        )}
                                      </button>
                                      <button
                                        onClick={handleClear}
                                        className="cancel_clear_btn px-3"
                                      >
                                        Clear
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                              {/* <div className="attached_files_preview d-flex flex-wrap">
                    {noteInput.files.map((file, index) => (
                      <div key={index} className="attached_file px-1 ms-2 mb-2">
                        <span>{file.name}</span>
                        &nbsp;&nbsp;
                        <button
                          onClick={() => handleRemoveFile(index)}
                          className="attached_file_remove_btn"
                        >
                          <img src={removeIcon} alt="remove" />
                        </button>
                      </div>
                    ))}
                  </div> */}
                            </div>
                          </section>
                        </>
                      )
                  )}
                </section>
              </section>
            )}

            {mainactiveType === "Related_Vehicles" && (
              <section className="Related_Vehicles_body ">
                <div className="box2">
                  <div className="search-container">
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search...."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                    <div className="search-button">
                      <i class="bi bi-search"></i>
                    </div>
                  </div>

                  {loading ? (
                    <p>Loading...</p>
                  ) : filteredVehicles.length > 0 ? (
                    filteredVehicles.map((vehicle, index) => (
                      <div className="carRow mt-3" key={vehicle.id}>
                        <div className="row1 rows">
                          <div className="d-flex align-items-center">
                            <img
                              style={{
                                width: "80px",
                                height: "70px",
                                objectFit: "cover",
                                borderRadius: "7px",
                              }}
                              src={
                                vehicle.Vehicle_Image_URL ||
                                "./images/Rectangle 83.svg"
                              }
                              alt=""
                            />
                          </div>
                          <div className="carDetail">
                            <div className="line1 d-flex align-items-start">
                              <p
                                className="headP"
                                title={vehicle.Full_Name}
                                onClick={() => handleVehicleClick(vehicle.id)}
                              >
                                {vehicle.Full_Name || "No Name Available"}
                              </p>

                              <button
                                className="img-button mx-2"
                                style={{ width: "auto" }}
                                onClick={() => {
                                  window.ZOHO.CRM.UI.Record.open({
                                    Entity: "Vehicles",
                                    RecordID: vehicle?.Vehicle_id?.id,
                                  }).then(function(data) {
                                    console.log(data);
                                  });
                                }}
                              >
                                <img
                                  src="/img/money-send.svg"
                                  style={{ width: "10px" }}
                                  alt=""
                                />{" "}
                                Open
                              </button>
                            </div>
                            <div className="line2 d-flex align-items-center mt-1">
                              <div
                                className="price"
                                style={{ marginRight: "3px" }}
                              >
                                Top Offer : ${vehicle.offer_amount || "N/A"}
                              </div>
                              <div
                                className="lead_score"
                                style={{ marginRight: "3px" }}
                              >
                                Lead Score :{" "}
                                <b>{vehicle.Lead_Score || "N/A"}</b>
                              </div>
                              <div className="Relatedvehicle_status">
                                Status :{" "}
                                <b>{vehicle.Progress_Status || "N/A"}</b>
                              </div>
                            </div>
                            <div className="line3 d-flex align-items-center mt-1">
                              <input
                                className="makeofferbox"
                                style={{ marginRight: "3px" }}
                                type="text"
                                placeholder="Make Offer"
                                value={offerValues[vehicle.id] || ""}
                                onChange={(e) =>
                                  handleOfferInputChange(vehicle.id, e)
                                }
                              />
                              <div
                                className="offer text-center"
                                onClick={() => handleOfferClick(vehicle.id)}
                              >
                                Offer
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No Related Vehicles Available</p>
                  )}
                </div>
              </section>
            )}

            <Modal show={showErrorModal} onHide={closeErrorModal}>
              <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
              </Modal.Header>
              <Modal.Body>Please select a vehicle.</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeErrorModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </section>
      )}
    </>
  );
}

export default Notes_main;
